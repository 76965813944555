import React from "react";
import ToggleButton from "react-toggle-button";

const FilterBar = ({ useFilter, useShowSelected, useShowNotSelected }) => {
  const [filter, setFilter] = useFilter;
  const [showSelected, setShowSelected] = useShowSelected;
  const [showNotSelected, setShowNotSelected] = useShowNotSelected;

  const showSelectedHandler = () => {
    if (showSelected && showNotSelected) {
      setShowNotSelected(false);
    } else if (showSelected) {
      setShowSelected(false);
      setShowNotSelected(true);
    } else {
      setShowSelected(true);
      setShowNotSelected(true);
    }
  };

  return (
    <div className="flex items-center h-10 w-full border-r-2 border-dashed text-xs">
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.activity ? "bg-blue-400" : "bg-gray-200"
        }`}
      >
        <span>액</span>
        <ToggleButton
          value={filter.activity}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              activity: !prevFilter.activity,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.accommodation ? "bg-red-400" : "bg-gray-200"
        }`}
      >
        <span>숙</span>
        <ToggleButton
          value={filter.accommodation}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              accommodation: !prevFilter.accommodation,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.restaurant ? "bg-yellow-400" : "bg-gray-200"
        }`}
      >
        <span>식</span>
        <ToggleButton
          value={filter.restaurant}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              restaurant: !prevFilter.restaurant,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.tourspot ? "bg-purple-400" : "bg-gray-200"
        }`}
      >
        <span>관</span>
        <ToggleButton
          value={filter.tourspot}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              tourspot: !prevFilter.tourspot,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.cafe ? "bg-green-400" : "bg-gray-200"
        }`}
      >
        <span>카</span>
        <ToggleButton
          value={filter.cafe}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              cafe: !prevFilter.cafe,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.bar ? "bg-indigo-400" : "bg-gray-200"
        }`}
      >
        <span>바</span>
        <ToggleButton
          value={filter.bar}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              bar: !prevFilter.bar,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 ${
          filter.grave ? "bg-indigo-400" : "bg-gray-200"
        }`}
      >
        <span>무</span>
        <ToggleButton
          value={filter.grave}
          onToggle={() =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              grave: !prevFilter.grave,
            }))
          }
          thumbStyle={{ borderRadius: 5 }}
          trackStyle={{ borderRadius: 5 }}
        />
      </div>
      <div
        className={`flex items-center justify-evenly flex-1 h-full gap-x-2 p-2 cursor-pointer ${
          showSelected && showNotSelected
            ? "bg-pink-400"
            : showNotSelected
            ? "bg-pink-600"
            : "bg-gray-200"
        }`}
        onClick={showSelectedHandler}
      >
        <span>
          {showSelected && showNotSelected
            ? "All"
            : showNotSelected
            ? "Not selected"
            : showSelected
            ? "Selected"
            : ""}
        </span>
      </div>
    </div>
  );
};

export default FilterBar;
