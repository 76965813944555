import React, { useContext, useEffect, useState } from "react";
import { getSalesOrders, updateSalesOrder } from "../../api/SalesOrders";

import { UserContext } from "../../contexts/user";

const SalesOrderTable = () => {
  const [user] = useContext(UserContext);
  const [salesOrers, setSalesOrders] = useState([]);

  const STATUS_PAID = 1;
  const STATUS_CONFIRMED = 2;
  const STATUS_CANCEL_REQUESTED = 3;
  const STATUS_CANCELED = 4;

  const editHandler = async (s) => {
    switch (s.status) {
      case STATUS_PAID:
        if (window.confirm(`${s.code} - 주문을 확정하시겠습니까?`)) {
          await updateSalesOrder(s.id, STATUS_CONFIRMED);
          await getSalesOrders().then(setSalesOrders);
        } else {
          alert("요청이 취소되었습니다.");
        }
        break;

      case STATUS_CANCEL_REQUESTED:
        if (window.confirm(`${s.code} - 주문을 취소하시겠습니까?`)) {
          await updateSalesOrder(s.id, STATUS_CANCELED);
          await getSalesOrders().then(setSalesOrders);
        } else {
          alert("요청이 취소되었습니다.");
        }
        break;

      default:
        window.alert("변경할 수 있는 상태가 없습니다.");
    }
  };

  useEffect(() => {
    getSalesOrders().then(setSalesOrders);
  }, [user]);

  return (
    <div className="flex flex-col w-full">
      <table className="flex-1">
        <thead>
          <tr>
            <th>code</th>
            <th>id</th>
            <th>user_id</th>
            <th>user_name</th>
            <th>status</th>
            <th>orders</th>
            <th>paid_amount</th>
            <th>customer_name</th>
            <th>customer_mobile</th>
            <th>cancel_amount</th>
            <th>updated_at</th>
            <th>수정</th>
          </tr>
        </thead>
        <tbody>
          {salesOrers &&
            salesOrers
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((s, index) => {
                return (
                  <tr
                    key={index}
                    className={`border-b border-t ${
                      index % 2 === 0 ? "bg-green-100" : ""
                    }`}
                  >
                    <td className="text-center">{s.code}</td>
                    <td className="text-center">{s.id}</td>
                    <td className="text-center">{s.userId}</td>
                    <td className="text-center">{s.userName}</td>
                    <td className="text-center">{s.status}</td>
                    <td className="text-center">
                      {s.orders.map((o) => (
                        <div>
                          {`${o.productName} ${o.optionName}(${o.startDate} ${o.startTime}) -> ${o.count}`}
                        </div>
                      ))}
                    </td>
                    <td className="text-center">{s.paidAmount}</td>
                    <th className="text-center">{s.customerName}</th>
                    <th className="text-center">{s.customerMobile}</th>
                    <th className="text-center">{s.cancelRequestedAmount}</th>
                    <th className="text-center">{s.updatedAt}</th>
                    <td className="flex gap-x-4">
                      {s.status === STATUS_PAID ? (
                        <button
                          className="bg-blue-200 border-2 border-black flex-center"
                          onClick={() => editHandler(s)}
                        >
                          {" "}
                          확정
                        </button>
                      ) : s.status === STATUS_CANCEL_REQUESTED ? (
                        <button
                          className="bg-blue-200 border-2 border-black flex-center"
                          onClick={() => editHandler(s)}
                        >
                          {" "}
                          취소
                        </button>
                      ) : (
                        <button
                          className="border-2 border-black flex-center disabled"
                          onClick={() => editHandler(s)}
                        >
                          {s.status === STATUS_CONFIRMED ? "확정됨" : "취소됨"}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default SalesOrderTable;
