import Axios from "axios";

const getZones = async () => {
  try {
    const { data } = await Axios.get("/v2/zones");

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/*
{
  coords: [],
  centerLat: number,
  centerLng: number,
}
*/
const createZone = async (zone) => {
  try {
    const { data } = await Axios.post("/v2/zones", { ...zone });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateZone = async (id, zone) => {
  try {
    const { data } = await Axios.put(`/v2/zones/${id}`, { ...zone });

    const { zone: updatedZone, status } = data;

    if (status !== "updated") throw new Error(`Wrong status: ${status}`);
    return updatedZone;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const getZonePois = async (id) => {
  try {
    const { data } = await Axios.get(`/v2/zones/${id}/pois`);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { getZones, createZone, updateZone, getZonePois };
