import React, { useContext } from "react";

import INPUT_TYPE from "../../Types/InputType";
import { WizardContext } from "../../contexts/wizard";

const EditFormInput = ({ fieldName, setAreaSmalls, areas, isNew }) => {
  const [ctx, dispatch] = useContext(WizardContext);
  const { editTarget } = ctx;

  const inputChangeHandler = (e) => {
    const key = e.currentTarget.name;
    const value = e.currentTarget.value;

    dispatch({ type: "UPDATE_TARGET", key: key, value: value });
  };

  const areaChangeHandler = (e) => {
    const key = e.currentTarget.name;
    const [prevAreaLarge] = inputState.value.split(" ");
    const text = e.currentTarget.value;
    let newArea;

    if (key === "areaLarge") {
      if (areas[text]) {
        setAreaSmalls(areas[text]);
      }
      newArea = text + " ";
    } else if (key === "areaSmall") {
      newArea = prevAreaLarge + " " + text;
    }

    dispatch({ type: "UPDATE_TARGET", key: "area", value: newArea });
  };

  const ensureMenuType = (menu) => {
    if (!menu) {
      return "";
    } else if (typeof menu === "string") {
      return menu;
    } else return menu.join("\n");
  };

  const inputState = editTarget[fieldName];

  switch (inputState.type) {
    case INPUT_TYPE.createOnly:
      if (isNew) {
        return (
          <input
            className="flex-1 p-1 border border-gray-500"
            type="text"
            name={fieldName}
            value={inputState.value}
            onChange={inputChangeHandler}
          />
        );
      } else {
        return (
          <div>
            <span> {inputState.value} </span>
          </div>
        );
      }
    case INPUT_TYPE.hidden:
      return null;
    case INPUT_TYPE.immutable:
      return (
        <div>
          <span> {inputState.value} </span>
        </div>
      );
    case INPUT_TYPE.area:
      const [areaLarge, areaSmall] = inputState.value.split(" ");

      return (
        <div className="flex flex-col">
          <input
            list="areaLargeList"
            name="areaLarge"
            className="p-1 mb-1 border border-gray-500"
            onChange={areaChangeHandler}
            value={areaLarge}
          />
          <input
            list="areaSmallList"
            name="areaSmall"
            className="p-1 border border-gray-500"
            value={areaSmall}
            onChange={areaChangeHandler}
          />
        </div>
      );
    case INPUT_TYPE.dropdown:
      return (
        <select
          className="flex-1 p-1 border border-gray-500"
          name={fieldName}
          value={inputState.value}
          onChange={inputChangeHandler}
        >
          <option>선택</option>
          {inputState.options.map((option) => {
            return (
              <option value={option[1]} key={option[1]}>
                {option[0]}
              </option>
            );
          })}
        </select>
      );
    case INPUT_TYPE.fiveRating:
      return (
        <input
          type="number"
          min="0.0"
          max="5.0"
          step="0.01"
          name={fieldName}
          className="p-1 border border-gray-500"
          value={inputState.value}
          onChange={inputChangeHandler}
        />
      );
    case INPUT_TYPE.tenRating:
      return (
        <input
          type="number"
          min="0.0"
          max="10.0"
          step="0.1"
          name={fieldName}
          className="p-1 border border-gray-500"
          value={inputState.value}
          onChange={inputChangeHandler}
        />
      );
    case INPUT_TYPE.decimal:
      return (
        <input
          type="number"
          min="0.0"
          step="0.0000001"
          name={fieldName}
          className="p-1 border border-gray-500"
          value={inputState.value}
          onChange={inputChangeHandler}
        />
      );
    case INPUT_TYPE.menu:
      return (
        <textarea
          name={fieldName}
          className="p-1 border border-gray-500"
          value={ensureMenuType(inputState.value)}
          onChange={inputChangeHandler}
        />
      );
    default:
      return (
        <input
          className="flex-1 p-1 border border-gray-500"
          type="text"
          name={fieldName}
          value={inputState.value}
          onChange={inputChangeHandler}
        />
      );
  }
};

export default EditFormInput;
