import Axios from "axios";

const postSurvey = async (params, stage) => {
  try {
    const { data } = await Axios.post(`/v2/surveys`, { params, stage });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { postSurvey };
