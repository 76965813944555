import React, { createContext, useReducer } from "react";

import INPUT_TYPE from "../Types/InputType";
import POI_OBJ from "../Types/POI_OBJ";
import WIZARD_CONTEXT from "../Types/WizardContext";

const initialState = { ...WIZARD_CONTEXT };

const getPOIInitVal = (obj) => {
  switch (obj.type) {
    case INPUT_TYPE.dropdown:
      return obj.initialValue[1];
    default:
      return obj.initialValue;
  }
};

const initEditTarget = (poi) => {
  return Object.entries(POI_OBJ).reduce((acc, [fieldName, obj]) => {
    acc[fieldName] = {
      ...obj,
      value: poi && poi[fieldName] ? poi[fieldName] : getPOIInitVal(obj),
    };
    return acc;
  }, {});
};

/*
action:
{
  type: _,
  key: _,
  value: _,
}
*/

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT":
      return { ...initialState };

    case "UPDATE_POIS":
      return { ...state, pois: action.value };

    case "UPDATE_ZONES":
      return { ...state, zones: action.value };

    case "UPDATE_SINGLE_POI":
      return {
        ...state,
        pois: { ...state.pois, [action.value.id]: action.value },
      };

    case "SELECT":
      const selectedPoi = action.value;
      const newEditTarget = initEditTarget(selectedPoi);

      return {
        ...state,
        editTarget: newEditTarget,
        isNew: false,
        showEdit: true,
      };

    case "CLOSE_EDIT":
      return { ...state, showEdit: false };

    case "UPDATE_TARGET":
      if (action.key) {
        const { key, value } = action;

        state.editTarget[key].value = value;
        return { ...state };
      } else return { ...state };

    case "UPDATE_LATLNG":
      if (state.editTarget) {
        const { lat, lng } = action.value;

        state.editTarget.latitude.value = lat;
        state.editTarget.longitude.value = lng;
        return { ...state };
      } else return state;

    case "OPEN_NEW":
      const emptyEditTarget = initEditTarget(null);

      return {
        ...state,
        editTarget: emptyEditTarget,
        showEdit: true,
        isNew: true,
      };

    case "TOGGLE_MODE":
      const newMode =
        state.mode === "POI_EDITOR" ? "ZONE_EDITOR" : "POI_EDITOR";

      return {
        ...initialState,
        map: state.map,
        pois: state.pois,
        zones: state.zones,
        mode: newMode,
      };

    case "TOGGLE_SHOW_EXISTING_ZONES":
      return {
        ...state,
        showExistingZones: !state.showExistingZones,
      };

    case "UPDATE_EDITING_ZONE_PATH":
      return {
        ...state,
        editingZonePath: action.value,
      };

    case "DELETE_SINGLE_EDITIING_ZONE_PATH":
      state.editingZonePath.splice(action.value, 1);

      return { ...state };

    case "UPDATE_EDITING_ZONE_ID":
      return {
        ...state,
        editingZoneId: action.value,
      };

    case "SET_PARENT_ID":
      return {
        ...state,
        parentItnId: action.value,
      };

    default:
      console.error("no reducer");
      return state;
  }
};

const WizardContext = createContext([initialState, () => initialState]);

const WizardProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
};

export { WizardContext, WizardProvider };
