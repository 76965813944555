/*global kakao*/
import React, { useCallback, useContext, useRef, useState } from "react";

import Modes from "../../Types/Modes";
import { WizardContext } from "../../contexts/wizard";
import { getBgColor } from "../../util/Util";
import { useEffect } from "react";
import SelectedPoiAttrType from "../../Types/SelectedPoiAttrType";
import POI_TYPE from "../../Types/PoiType";
import _ from "lodash";

const Map = ({
  activePois,
  selectedPois,
  useShowSelected,
  useShowNotSelected,
  setMapSelectedPoiId,
  filter,
}) => {
  const [ctx, dispatch] = useContext(WizardContext);
  const {
    editTarget,
    showEdit,
    mode,
    zones,
    editingZonePath,
    editingZoneId,
    showExistingZones,
  } = ctx;

  const markerMinZ = useRef(0);
  const markerOverlays = useRef([]);
  const zoneMarkers = useRef([]);
  const polygons = useRef([]);
  const editingPolygon = useRef(null);
  const zoneNameOverlay = useRef(null);
  const [map, setMap] = useState(null);
  const [showSelected, setShowSelected] = useShowSelected;
  const [showNotSelected, setShowNotSelected] = useShowNotSelected;
  const [main, setMain] = useState(true);
  const [alt, setAlt] = useState(true);
  const [dayLength, setDayLength] = useState(0);
  const [selectedDays, setSelectedDays] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
  ]);

  useEffect(() => {
    let _dayLength = 0;
    selectedPois.forEach((poi) => {
      if (poi.attr === SelectedPoiAttrType.newday) {
        _dayLength++;
      }
    });
    setDayLength(_dayLength);
  }, [selectedPois]);
  // Utility functions
  const buildOverlayContent = (poi) => {
    return `<div class="p-1 border text-xs rounded-full cursor-pointer opacity-75 bg-${getBgColor(
      poi
    )}-400" id="overlay-${poi.id}">${poi.name.slice(0, 10)}</div>`;
  };

  const drawPolygon = (path, map) => {
    const pathPositions = path.map(([lat, lng]) => {
      return new kakao.maps.LatLng(lat, lng);
    });

    const newPolygon = new kakao.maps.Polygon({
      map: map,
      path: pathPositions,
      strokeWeight: 3,
      strokeColor: "#39DE24",
      strokeOpacity: 0.8,
      strokeStyle: "solid",
      fillColor: "#A2FF99",
      fillOpacity: 0.7,
    });

    return newPolygon;
  };

  const drawPolygonByLatLng = (path, map) => {
    const newPolygon = new kakao.maps.Polygon({
      map: map,
      path: path,
      strokeWeight: 3,
      strokeColor: "#39DE24",
      strokeOpacity: 0.8,
      strokeStyle: "solid",
      fillColor: "#A2FF99",
      fillOpacity: 0.7,
    });

    return newPolygon;
  };

  const clearEditingPolygon = () => {
    if (editingPolygon.current) {
      editingPolygon.current.setMap(null);
      editingPolygon.current = null;
    }

    zoneMarkers.current.forEach((zm) => {
      zm.setMap(null);
    });
    zoneMarkers.current = [];
  };

  const hideExistingPolygons = () => {
    polygons.current.forEach(([p, key]) => p.setMap(null));
    polygons.current = [];

    zoneNameOverlay.current.setMap(null);
  };

  const showExistingPolygons = () => {
    const zonesArray = Object.entries(zones).filter(
      ([key]) => key !== editingZoneId
    );
    const newPolygons = zonesArray
      .filter(([key]) => key !== editingZoneId)
      .map(([key, { coords }]) => {
        return [drawPolygon(coords, map), key];
      });

    newPolygons.forEach(([p, key]) => {
      kakao.maps.event.addListener(p, "mouseover", (e) => {
        p.setOptions({ fillColor: "#09f" });

        zoneNameOverlay.current.setContent(
          `<div class="absolute ml-10 -top-4 left-10 p-2 border bg-white opacity-75">${key}</div>`
        );

        zoneNameOverlay.current.setPosition(e.latLng);
        zoneNameOverlay.current.setMap(map);
      });

      kakao.maps.event.addListener(p, "mousemove", (e) => {
        zoneNameOverlay.current.setPosition(e.latLng);
      });

      kakao.maps.event.addListener(p, "mouseout", (e) => {
        p.setOptions({ fillColor: "#A2FF99" });
        zoneNameOverlay.current.setMap(null);
      });

      kakao.maps.event.addListener(p, "click", (e) => {
        kakao.maps.event.preventMap();
        selectZone(key);
      });
    });

    polygons.current = newPolygons;
  };

  const selectZone = (id) => {
    clearEditingPolygon();
    hideExistingPolygons();

    const path = zones[id].coords;

    const newMarkers = path.map(([lat, lng], index) => {
      const markerPosition = new kakao.maps.LatLng(lat, lng);
      const marker = new kakao.maps.Marker({
        map: map,
        position: markerPosition,
        draggable: true,
      });

      kakao.maps.event.addListener(marker, "dragend", () => {
        updateEditingPolygon();
      });

      kakao.maps.event.addListener(marker, "rightclick", () => {
        deleteZoneMarker(marker);
        updateEditingPolygon();
      });

      return marker;
    });

    zoneMarkers.current = newMarkers;
    updateEditingPolygon();

    // dispatch({ type: "TOGGLE_SHOW_EXISTING_ZONES" });
    dispatch({ type: "UPDATE_EDITING_ZONE_ID", value: id });
    dispatch({ type: "UPDATE_EDITING_ZONE_PATH", value: path });

    showExistingPolygons();
  };

  const updateEditingPolygon = () => {
    if (editingPolygon.current) editingPolygon.current.setMap(null);

    const path = zoneMarkers.current.map((m) => m.getPosition());
    editingPolygon.current = drawPolygonByLatLng(path, map);

    const coords = zoneMarkers.current.map((zm) => {
      const position = zm.getPosition();
      return [position.getLat(), position.getLng()];
    });

    dispatch({ type: "UPDATE_EDITING_ZONE_PATH", value: coords });
  };

  const moveLower = (marker, overlay) => {
    marker.setZIndex(markerMinZ.current - 1);
    overlay.setZIndex(markerMinZ.current - 1);
    markerMinZ.current -= 1;
  };

  const deleteZoneMarker = (marker) => {
    if (zoneMarkers.current.length <= 1) return;

    const index = zoneMarkers.current.indexOf(marker);

    zoneMarkers.current[index].setMap(null);
    zoneMarkers.current.splice(index, 1);

    dispatch({ type: "DELETE_SINGLE_EDITIING_ZONE_PATH", value: index });
  };

  const mapClickHandler = useCallback(
    (e) => {
      var latlng = e.latLng;
      const lat = latlng.getLat().toString().slice(0, 10);
      const lng = latlng.getLng().toString().slice(0, 10);
      const markerPosition = new kakao.maps.LatLng(lat, lng);

      const marker = new kakao.maps.Marker({
        map: map,
        position: markerPosition,
        draggable: true,
      });

      kakao.maps.event.addListener(marker, "dragend", () => {
        updateEditingPolygon();
      });

      kakao.maps.event.addListener(marker, "rightclick", function (e) {
        deleteZoneMarker(marker);
        updateEditingPolygon();
      });

      zoneMarkers.current.push(marker);
      updateEditingPolygon();
    },
    [map, dispatch]
  );

  // Initialize Map
  useEffect(() => {
    const onload = () => {
      const container = document.getElementById("map-container");
      const options = {
        center: new kakao.maps.LatLng(37.5684, 126.9794), // 지도의 중심좌표
        level: 9, // 지도의 확대 레벨
        disableDoubleClickZoom: true,
      };
      const _map = new kakao.maps.Map(container, options); // 지도를 생성합니다
      zoneNameOverlay.current = new kakao.maps.CustomOverlay({ map: _map });

      _map.setCursor("auto");

      kakao.maps.event.addListener(_map, "dblclick", function (mouseEvent) {
        var latlng = mouseEvent.latLng;
        const lat = latlng.getLat().toString().slice(0, 10);
        const lng = latlng.getLng().toString().slice(0, 10);

        if (window.confirm(`위도: ${lat} / 경도: ${lng}`)) {
          dispatch({
            type: "UPDATE_LATLNG",
            value: { lat, lng },
          });
          console.log(`${lat} ${lng}`);
        }
      });

      setMap(_map);
    };

    onload();
  }, [dispatch]);

  // Load POI markers
  useEffect(() => {
    const createMarkerOverlay = (poi) => {
      const markerPosition = new kakao.maps.LatLng(poi.latitude, poi.longitude);
      const marker = new kakao.maps.Marker({
        map: map,
        position: markerPosition,
      });

      const overlay = new kakao.maps.CustomOverlay({
        map: map,
        position: markerPosition,
        content: buildOverlayContent(poi),
        clickable: true,
      });

      window.marker = marker;

      kakao.maps.event.addListener(marker, "click", function () {
        dispatch({ type: "SELECT", value: poi });
        setMapSelectedPoiId(poi.id);
      });
      kakao.maps.event.addListener(marker, "rightclick", function () {
        moveLower(marker, overlay);
      });

      // const overlayElem = document.getElementById(`overlay-${poi.id}`);
      // if (overlayElem)
      //   overlayElem.addEventListener("click", (e) => {
      //     dispatch({ type: "SELECT", value: poi });
      //   });
      // console.log(overlayElem);

      return [marker, overlay];
    };

    const mainFilter = (poi) => {
      if (
        (main && poi.attr !== SelectedPoiAttrType.sub) ||
        (alt && poi.attr === SelectedPoiAttrType.sub) ||
        (poi.attr === undefined && showNotSelected)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const dayFilter = (poi) => {
      if (poi.day !== undefined && selectedDays.includes(poi.day)) {
        return true;
      } else if (poi.day === undefined) {
        return true;
      } else {
        return false;
      }
    };

    markerOverlays.current.forEach(([marker, overlay]) => {
      marker.setMap(null);
      overlay.setMap(null);
    });

    // TODO: deduplicate active and selected & separate marker styles
    let day = 0;
    const _selectedPois = selectedPois.map((poi) => {
      if (poi.attr === SelectedPoiAttrType.newday) {
        day += 1;
      }
      return {
        ...poi,
        day,
      };
    });
    const pois =
      showSelected && showNotSelected
        ? _.unionBy(activePois, _selectedPois, "id")
        : showSelected
        ? _.uniqBy(_selectedPois, "id")
        : showNotSelected
        ? _.uniqBy(
            activePois
              .filter((poi) => poi !== undefined)
              .filter((poi) => !_selectedPois.some((p) => p.id === poi.id)),
            "id"
          )
        : [];
    const newMarkerOverlays = pois
      .filter((poi) => poi !== undefined)
      .filter((poi) =>
        filter.grave
          ? filter[POI_TYPE[poi.poiTypeId]] || poi.grade === "무덤"
          : filter[POI_TYPE[poi.poiTypeId]] && poi.grade !== "무덤"
      )
      .filter((poi) => dayFilter(poi))
      .filter((poi) => mainFilter(poi))
      .map((poi) => createMarkerOverlay(poi));
    markerOverlays.current = newMarkerOverlays;
  }, [
    activePois,
    selectedPois,
    showSelected,
    showNotSelected,
    dispatch,
    map,
    main,
    alt,
    selectedDays,
    filter,
  ]);

  // Relayout Map
  useEffect(() => {
    if (map) {
      map.relayout();
    }
  }, [showEdit, map]);

  // Pan Map
  useEffect(() => {
    const panToTarget = (target) => {
      const moveLatLng = new kakao.maps.LatLng(
        target.latitude.value,
        target.longitude.value
      );

      map.panTo(moveLatLng);
    };

    if (editTarget && editTarget.latitude && editTarget.latitude.value) {
      panToTarget(editTarget);
    }
  }, [map, editTarget]);

  // Update Zones
  useEffect(() => {
    if (map) {
      clearEditingPolygon();
      hideExistingPolygons();
      kakao.maps.event.removeListener(map, "click", mapClickHandler);
      if (mode === Modes.ZONE_EDITOR) {
        showExistingPolygons();
        kakao.maps.event.addListener(map, "click", mapClickHandler);
      }
    }
  }, [map, zones, mode, mapClickHandler]);

  // Clear editing zone when new zone clicked
  useEffect(() => {
    if (editingZonePath.length === 0) {
      clearEditingPolygon();
    }
  }, [editingZonePath]);

  useEffect(() => {
    if (showExistingZones === true) {
      showExistingPolygons();
    } else {
      hideExistingPolygons();
    }
  }, [showExistingZones]);

  return (
    <div className="w-full h-full relative" id="map-container">
      <div className="absolute top-0 right-0 bg-white z-10 grid grid-cols-3 gap-4">
        <div>
          <div>Select</div>
          <div>
            <input
              type="checkbox"
              checked={showSelected}
              onChange={(e) => setShowSelected((prev) => !prev)}
            />
            Selected
          </div>
          <div>
            <input
              type="checkbox"
              checked={showNotSelected}
              onChange={(e) => setShowNotSelected((prev) => !prev)}
            />
            Unelected
          </div>
        </div>
        <div>
          <div>n일차</div>
          {[...Array(dayLength).keys()]
            .map((day) => day + 1)
            .map((day) => (
              <div key={day}>
                <input
                  type="checkbox"
                  checked={selectedDays.includes(day) ? true : false}
                  onChange={() => {
                    setSelectedDays((days) => {
                      const index = days.findIndex((d) => d === day);
                      if (index > -1) {
                        return days
                          .slice(0, index)
                          .concat(days.slice(index + 1));
                      }
                      return days.concat(day);
                    });
                  }}
                />
                {day}
              </div>
            ))}
        </div>
        <div>
          <div>메인/후보</div>
          <div>
            <input
              type="checkbox"
              checked={main}
              onChange={(e) => setMain((prev) => !prev)}
            />
            메인
          </div>
          <div>
            <input
              type="checkbox"
              checked={alt}
              onChange={(e) => setAlt((prev) => !prev)}
            />
            후보
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Map;
