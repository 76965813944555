const POI_TYPE = {
  2: "tourspot",
  3: "activity",
  4: "accommodation",
  6: "restaurant",
  7: "cafe",
  10: "bar",
};

export default POI_TYPE;
