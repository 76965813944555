import React, { useCallback, useContext, useEffect, useState } from "react";

import ControlBar from "./ControlBar";
import Course from "./Course";
import { DragDropContext } from "react-beautiful-dnd";
import Edit from "./Edit";
import FilterBar from "./FilterBar";
import List from "./List";
import Map from "./Map";
import SearchBar from "./SearchBar";
import SelectedPoiAttrType from "../../Types/SelectedPoiAttrType";
import { UserContext } from "../../contexts/user";
import { WizardContext } from "../../contexts/wizard";
import _ from "lodash";
import { getPois } from "../../api/Pois";
import { getTransports } from "../../api/Transports";
import { getZones } from "../../api/Zones";
import inside from "point-in-polygon";
import { toast } from "react-toastify";

const COURSE_ID = "course-list";
const LIST_ID = "poi-list";

const Wizard = () => {
  const [user] = useContext(UserContext);
  const [ctx, dispatch] = useContext(WizardContext);
  const { pois, showEdit, zones } = ctx;

  const [areas, setAreas] = useState([]);
  // const [showEdit, setShowEdit] = useState(true);
  const [activePois, setActivePois] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedPois, setSelectedPois] = useState([]);
  const [mapSelectedPoiId, setMapSelectedPoiId] = useState(0);

  const [filter, setFilter] = useState({
    activity: true,
    accommodation: true,
    restaurant: true,
    tourspot: true,
    cafe: true,
    bar: true,
    grave: false,
  });
  const [showSelected, setShowSelected] = useState(true);
  const [showNotSelected, setShowNotSelected] = useState(true);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    let poiList = Object.values(pois)
      .filter((poi) => {
        if (searchType === "") {
          return false;
        } else if (searchType === "zone") {
          if (zones[searchValue] === undefined) {
            return false;
          }
          return inside(
            [parseFloat(poi.latitude), parseFloat(poi.longitude)],
            zones[searchValue].coords
          );
        } else if (searchType === "id") {
          return poi[searchType] === parseInt(searchValue);
        } else {
          return poi[searchType].includes(searchValue);
        }
      })
      .filter(
        (poi) =>
          showSelected && showNotSelected
            ? true
            : showNotSelected
            ? !selectedPois.includes(poi)
            : selectedPois.includes(poi) // 3
      )
      .sort((poi1, poi2) => {
        if (poi1.name > poi2.name) {
          return 1;
        } else if (poi1.name < poi2.name) {
          return -1;
        } else {
          return 0;
        }
      });
    if (mapSelectedPoiId > 0) {
      const index = poiList.findIndex((poi) => poi.id === mapSelectedPoiId);
      poiList = reorder(poiList, index, 0);
    }
    setActivePois(poiList);
  }, [
    searchType,
    searchValue,
    showSelected,
    showNotSelected,
    pois,
    selectedPois,
    mapSelectedPoiId,
  ]);

  useEffect(() => {
    setSelectedPois((prevSelectedPois) =>
      prevSelectedPois.map((selectedPoi) => ({
        ...selectedPoi,
        ...pois[selectedPoi.id],
      }))
    );
  }, [pois]);

  const onDragEnd = async (result) => {
    try {
      const poi = {
        ...pois[result.draggableId],
        attr: SelectedPoiAttrType.normal,
      };
      let _selectedPois = [...selectedPois];
      const { source, destination } = result;
      if (!destination) {
        if (source && source.droppableId === COURSE_ID) {
          _selectedPois.splice(source.index, 1);
        }
      } else if (destination.droppableId === COURSE_ID) {
        if (source.droppableId === COURSE_ID) {
          _selectedPois = reorder(
            _selectedPois,
            source.index,
            destination.index
          );
          if (
            source.index === 0 &&
            _selectedPois[destination.index].attr === SelectedPoiAttrType.newday
          ) {
            _selectedPois[destination.index].attr = SelectedPoiAttrType.normal;
          }
        } else {
          _selectedPois.splice(destination.index, 0, poi);
        }
        if (destination.index === 0 && _selectedPois[destination.index + 1]) {
          _selectedPois[destination.index + 1].attr =
            SelectedPoiAttrType.normal;
        }
      }

      const poiIds = [];
      _selectedPois.forEach((poi) => {
        if (poi.attr !== SelectedPoiAttrType.sub) {
          poiIds.push(poi.id);
        }
      });
      const transports = await getTransports(poiIds);
      _selectedPois[0].attr = SelectedPoiAttrType.newday;
      let lastMainPoiId = poiIds[0];
      setSelectedPois(
        _selectedPois.map((poi) => {
          const transportIndex = transports.findIndex((trans) =>
            pois[lastMainPoiId]
              ? trans.to === poi.id && trans.from === pois[lastMainPoiId].id
              : trans.to === poi.id
          );
          if (poi.attr !== SelectedPoiAttrType.sub) {
            lastMainPoiId = poi.id;
          }
          return {
            ...poi,
            transportMin:
              transportIndex > -1 ? transports[transportIndex].min : undefined,
          };
        })
      );
    } catch (e) {
      console.log("🚀 ~ file: Wizard.js ~ line 179 ~ onDragEnd ~ e", e);
      alert("일정표를 업데이트하는데 실패했습니다. 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    const getAreaMap = (poiList) => {
      const areaList = Array.from(new Set(poiList.map((el) => el.area)));
      const areaMap = {};

      areaList.forEach((area) => {
        const [a1, a2] = area.split(" ");
        if (!areaMap[a1]) {
          areaMap[a1] = [];
        }
        areaMap[a1].push(a2);
      });

      return areaMap;
    };

    const initializeMapData = async () => {
      if (!user.token) return;
      const poiData = await getPois();
      const poiMap = {};

      poiData.results.forEach((poi) => {
        poiMap[poi.id] = poi;
      });

      dispatch({ type: "UPDATE_POIS", value: poiMap });

      const areaMap = getAreaMap(poiData.results);
      setAreas(areaMap);

      const zoneData = await getZones();
      const zoneMap = {};
      zoneData.results.forEach((zone) => {
        zoneMap[zone.id] = zone;
      });

      dispatch({ type: "UPDATE_ZONES", value: zoneMap });

      toast.info("위자드가 준비됐습니다 :)");
    };

    initializeMapData();
  }, [user, dispatch]);

  const getListedPois = useCallback(() => {
    if (showSelected && showNotSelected) {
      return _.unionBy(activePois, selectedPois, "id");
    } else if (showSelected) {
      return _.uniqBy(selectedPois, "id");
    } else if (showNotSelected) {
      return _.uniqBy(
        activePois
          .filter((poi) => poi !== undefined)
          .filter((poi) => !selectedPois.some((p) => p.id === poi.id)),
        "id"
      );
    } else {
      return [];
    }
  }, [activePois, selectedPois, showSelected, showNotSelected]);

  return !user.token ? (
    <div>로그인이 필요합니다.</div>
  ) : (
    <div className="flex flex-1 overflow-y-scroll">
      <div className="flex flex-col h-full">
        <ControlBar />
        <FilterBar
          useFilter={[filter, setFilter]}
          useShowSelected={[showSelected, setShowSelected]}
          useShowNotSelected={[showNotSelected, setShowNotSelected]}
        />
        <SearchBar
          searchHandler={(type, value) => {
            setSearchType(type);
            setSearchValue(value);
          }}
        />
        <div className="flex flex-auto w-full h-4/5">
          <DragDropContext onDragEnd={onDragEnd}>
            <Course
              id={COURSE_ID}
              useSelectedPois={[selectedPois, setSelectedPois]}
            />
            <List id={LIST_ID} filter={filter} activePois={getListedPois()} />
          </DragDropContext>
        </div>
      </div>
      {showEdit ? (
        <Edit areas={areas} useSelectedPois={[selectedPois, setSelectedPois]} />
      ) : null}
      <div className="flex-1">
        <Map
          activePois={activePois}
          selectedPois={selectedPois}
          filter={filter}
          useShowSelected={[showSelected, setShowSelected]}
          useShowNotSelected={[showNotSelected, setShowNotSelected]}
          setMapSelectedPoiId={setMapSelectedPoiId}
        />
      </div>
    </div>
  );
};

export default Wizard;
