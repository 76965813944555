const getBgColor = (poi) => {
  switch (poi.poiTypeId) {
    case 2:
      // TourSpot
      return "purple";
    case 3:
      // Activity
      return "blue";
    case 4:
      // Accommodation
      return "red";
    case 6:
      // Reastaurant
      return "yellow";
    case 7:
      // Cafe
      return "green";
    case 10:
      // Bar
      return "indigo";
    default:
      return "gray";
  }
};

export { getBgColor };
