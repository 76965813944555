import Axios from "axios";

const getSalesOrders = async () => {
  try {
    const { data } = await Axios.get(`/v2/sales_orders`);

    console.log(data);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateSalesOrder = async (id, status) => {
  try {
    const { data } = await Axios.put(`/v2/sales_orders/${id}`, { status });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { getSalesOrders, updateSalesOrder };
