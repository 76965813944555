import React from "react";

const Input = (props) => {
  const {
    keyName,
    label,
    type,
    state,
    setState,
    min,
    max,
    options,
    placeholder,
  } = props;

  const dateHandler = (e) => {
    const seasons = {
      12: "winter",
      "01": "winter",
      "02": "winter",
      "03": "spring",
      "04": "spring",
      "05": "spring",
      "06": "summer",
      "07": "summer",
      "08": "summer",
      "09": "fall",
      10: "fall",
      11: "fall",
    };

    const date = e.target.value;
    const month = date.split("-")[1];
    const season = seasons[month];
    console.log(date, month, season);
    setState({ ...state, "v-date": date, "v-season": season });
  };

  switch (type) {
    case "date":
      return (
        <div>
          <label htmlFor={keyName} className="text-lg font-bold mr-2">
            {label}
          </label>
          <input
            className="border"
            type="date"
            value={state[keyName]}
            id={keyName}
            onChange={dateHandler}
          />
        </div>
      );
    case "number":
      return (
        <div>
          <label htmlFor={keyName} className="text-lg font-bold mr-2">
            {label}
          </label>
          <input
            className="border"
            type="number"
            value={state[keyName]}
            id={keyName}
            min={min}
            max={max}
            onChange={(e) => setState({ ...state, [keyName]: e.target.value })}
          />
        </div>
      );
    case "dial":
      return (
        <div>
          <label htmlFor={keyName} className="text-sm font-bold mr-2">
            {label}
          </label>
          <input
            className="border w-16 text-sm"
            type="number"
            value={state[keyName]}
            id={keyName}
            step="0.1"
            onChange={(e) => setState({ ...state, [keyName]: e.target.value })}
          />
        </div>
      );
    case "checkbox":
      return (
        <div>
          <label htmlFor={keyName} className="text-lg font-bold mr-2">
            {label}
          </label>
          <input
            type="checkbox"
            value={state[keyName] === 1 ? true : false}
            id={keyName}
            onChange={() => {
              setState({ ...state, [keyName]: state[keyName] ^ 1 });
            }}
          />
        </div>
      );
    case "multi":
      const handleMultiChange = (val) => {
        const indexOfVal = state[keyName].indexOf(val);
        console.log(indexOfVal);

        if (indexOfVal === -1) {
          state[keyName].push(val);
          const updated = state[keyName];
          setState({ ...state, [keyName]: updated });
        } else {
          state[keyName].splice(indexOfVal, 1);
          const updated = state[keyName];
          setState({ ...state, [keyName]: updated });
        }
      };
      return (
        <div>
          <label className="text-lg font-bold mr-2">{label}</label>
          <div>
            {options.map(({ name, val }) => {
              return (
                <div key={val}>
                  <input
                    type="checkbox"
                    id={`${keyName}-${val}`}
                    checked={state[keyName].indexOf(val) > -1}
                    onChange={() => handleMultiChange(val)}
                  />
                  <label htmlFor={`${keyName}-${val}`}>{name}</label>
                </div>
              );
            })}
          </div>
        </div>
      );
    case "radio":
      return (
        <div>
          <label className="text-lg font-bold mr-2">{label}</label>
          <div>
            {options.map(({ name, val }) => {
              return (
                <div key={val}>
                  <input
                    type="radio"
                    onChange={() => {
                      setState({ ...state, [keyName]: val });
                    }}
                    checked={state[keyName] === val}
                    name={keyName}
                    id={val}
                  />
                  <label htmlFor={val}>{name}</label>
                </div>
              );
            })}
          </div>
        </div>
      );
    case "dropdown":
      return (
        <div className="flex flex-col">
          <label className="text-lg font-bold mr-2">{label}</label>
          <select
            className="border mx-2"
            value={state[keyName].toString()}
            onChange={(e) => {
              setState({ ...state, [keyName]: [e.target.value] });
            }}
          >
            {options.map((option, index) => {
              return (
                <option key={index} value={option.val}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
      );
    default:
      return (
        <div>
          <label htmlFor={keyName} className="text-lg font-bold mr-2">
            {label}
          </label>
          <input
            id={keyName}
            value={state[keyName]}
            placeholder={placeholder}
            onChange={(e) => {
              setState({ ...state, [keyName]: e.target.value });
            }}
            className="border"
            type="text"
          />
        </div>
      );
  }
};

const SurveyBox = (props) => {
  const { index } = props;

  return (
    <div key={index} className="mb-4">
      {Input(props)}
    </div>
  );
};

export default SurveyBox;
