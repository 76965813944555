import Axios from "axios";

const getDests = async (itn_id) => {
  try {
    const { data } = await Axios.get(`/v2/itineraries/${itn_id}/destinations`);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const postItn = async (itn, parentId) => {
  try {
    const { data } = await Axios.post(`/v2/itineraries`, {
      itn_from_wizard: itn,
      parentId,
    });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const postItnFromMirani = async (itn) => {
  try {
    const { data } = await Axios.post(`/v2/itineraries`, {
      itn: itn,
    });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { getDests, postItn, postItnFromMirani };
