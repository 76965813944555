import React, { useContext, useState } from "react";

import OutputTable from "./OutputTable";
import SurveyBox from "./SurveyBox";
import ToggleButton from "react-toggle-button";
import { UserContext } from "../../contexts/user";
import { postSurvey } from "../../api/DS";

const surveyList = [
  {
    keyName: "v-date",
    label: "언제 가시나요?",
    type: "date",
  },
  {
    keyName: "v-length",
    label: "며칠간 가시나요?",
    type: "number",
    min: 0,
    max: 4,
  },
  {
    keyName: "v-alone",
    label: "혼자 가시나요?",
    type: "checkbox",
  },
  {
    keyName: "v-u24",
    label: "24개월 미만 아이가 있나요?",
    type: "checkbox",
  },
  {
    keyName: "v-u48",
    label: "48개월 미만 아이가 있나요?",
    type: "checkbox",
  },
  {
    keyName: "v-o48",
    label: "48개월 초과 아이가 있나요?",
    type: "checkbox",
  },
  {
    keyName: "v-kids",
    label: "아이가 있나요?",
    type: "checkbox",
  },
  {
    keyName: "v-senior",
    label: "노인이 있나요?",
    type: "checkbox",
  },
  {
    keyName: "v-pets",
    label: "반려동물이 있나요?",
    type: "checkbox",
  },
  {
    keyName: "v-partysize",
    label: "전체 여행 인원은요?",
    type: "number",
    min: 0,
    max: 100,
  },
  {
    keyName: "v-transit",
    label: "대중교통 쓰나요?",
    type: "checkbox",
  },
  {
    keyName: "v-interest-one-pick",
    label: "One Pick Category",
    type: "dropdown",
    options: [
      { val: "", name: "없음" },
      { val: "2-guide", name: "가이드 투어/클래스" },
      { val: "3-festival", name: "지역 축제" },
      { val: "5-shop", name: "지역의 특색있는 상점들" },
      { val: "7-night", name: "야간 액티비티" },
      { val: "8-sea", name: "바다" },
      { val: "9-snap", name: "스냅촬영" },
      { val: "10-animal", name: "동물/이색체험" },
      { val: "11-theme", name: "테마파크/아쿠아리움" },
      { val: "12-sight", name: "경치관람(케이블카/모노레일)" },
      { val: "13-drive", name: "멋진 드라이브 코스" },
      { val: "14-garden", name: "수목원/정원" },
      { val: "15-cafe", name: "카페" },
      { val: "16-light", name: "등산/산책" },
      { val: "19-ski", name: "스키/스노우보드" },
      { val: "20-golf", name: "골프" },
      { val: "21-bicycle", name: "자전거" },
      { val: "22-railbike", name: "레일바이크" },
      { val: "23-paragliding", name: "패러글라이딩" },
      { val: "24-horse", name: "승마" },
      { val: "25-waterpark", name: "워터파크" },
      { val: "26-surfing", name: "서핑" },
      { val: "27-yacht", name: "요트투어" },
      { val: "28-fishing", name: "낚시" },
      { val: "29-scuba", name: "스쿠버다이빙" },
      { val: "30-zip", name: "집라이닝" },
      { val: "31-shooting", name: "사격" },
      { val: "32-cart", name: "카트/루지/썰매" },
      { val: "33-track", name: "트랙 드라이빙" },
      { val: "34-canoe", name: "카누/카약" },
      {
        val: ["35-soju", "36-wine", "37-mak", "38-beer", "39-cocktail"],
        name: "밤에 술 한잔",
      },
      { val: "40-history", name: "역사" },
      { val: "41-art", name: "미술/예술" },
      { val: "42-science", name: "자연/과학" },
      { val: "43-hotspring", name: "온천" },
      { val: "44-yoga", name: "요가/명상" },
      { val: "45-spa", name: "스파/마사지" },
      { val: "46-swim", name: "실내수영장" },
    ],
  },
  {
    keyName: "v-one-pick-id",
    label: "One Pick ID",
    type: "number",
    step: 1,
    min: 0,
    max: 99999,
  },
  {
    keyName: "v-dest-zone-keyword",
    label: "Dest zone keyword",
    placeholder: "예: 강원도 고성군",
  },
  {
    keyName: "clusterId",
    label: "Cluster ID",
    type: "number",
    step: 1,
    min: 0,
    max: 999,
  },
  {
    keyName: "m-preset",
    label: "Preset ID",
    type: "number",
    step: 1,
    min: 0,
    max: 9999,
  },
  {
    keyName: "v-distance",
    label: "Distance threshold",
    type: "number",
    step: 1,
    min: 0,
    max: 999,
  },
  {
    keyName: "v-user-id",
    label: "User id",
  },
  {
    keyName: "v-user-stay-latitude",
    label: "User stay lat",
  },
  {
    keyName: "v-user-stay-longitude",
    label: "User stay lng",
  },
  {
    keyName: "v-interest-general",
    label: "관심사를 골라주세요(1~5개)",
    type: "multi",
    options: [
      { val: "1-sports", name: "액티비티/스포츠" },
      { val: "2-guide", name: "가이드 투어/클래스" },
      { val: "47-drink", name: "밤에 술 한잔" },
      { val: "3-festival", name: "지역 축제" },
      { val: "4-museum", name: "미술관/박물관/역사유적" },
      { val: "5-shop", name: "지역의 특색있는 상점들" },
      { val: "6-wellness", name: "휴양/온천/웰니스" },
      { val: "7-night", name: "야간 액티비티" },
      { val: "8-sea", name: "바다" },
      { val: "9-snap", name: "스냅촬영" },
      { val: "10-animal", name: "동물/이색체험" },
      { val: "11-theme", name: "테마파크/아쿠아리움" },
      { val: "12-sight", name: "경치관람(케이블카/모노레일)" },
      { val: "13-drive", name: "멋진 드라이브 코스" },
      { val: "14-garden", name: "수목원/정원" },
      { val: "15-cafe", name: "카페" },
    ],
  },
  {
    keyName: "v-stay-budget",
    label: "숙소 예산?",
    type: "multi",
    options: [
      { val: "under10", name: "10만원 미만" },
      { val: "10to20", name: "10만원-20만원" },
      { val: "over20", name: "20만원 이상" },
      { val: "best", name: "최고를 원해요" },
    ],
  },
  {
    keyName: "v-stay-type",
    label: "숙소 종류?",
    type: "multi",
    options: [
      { val: "anything", name: "아무거나" },
      { val: "hotel", name: "호텔" },
      { val: "pension", name: "펜션" },
      { val: "camping", name: "캠핑" },
      { val: "guesthouse", name: "게스트하우스" },
    ],
  },
  {
    keyName: "v-interest-general",
    label: "등산 난이도는요?",
    type: "multi",
    options: [
      { val: "16-light", name: "가벼운 산책" },
      { val: "17-medium", name: "약간의 경사 있는 등산" },
      { val: "18-heavy", name: "본격 등산" },
    ],
  },
  {
    keyName: "v-interest-general",
    label: "관심있는 스포츠를 골라주세요",
    type: "multi",
    options: [
      { val: "19-ski", name: "스키/스노우보드" },
      { val: "20-golf", name: "골프" },
      { val: "21-bicycle", name: "자전거" },
      { val: "22-railbike", name: "레일바이크" },
      { val: "23-paragliding", name: "패러글라이딩" },
      { val: "24-horse", name: "승마" },
      { val: "25-waterpark", name: "워터파크" },
      { val: "26-surfing", name: "서핑" },
      { val: "27-yacht", name: "요트투어" },
      { val: "28-fishing", name: "낚시" },
      { val: "29-scuba", name: "스쿠버다이빙" },
      { val: "30-zip", name: "집라이닝" },
      { val: "31-shooting", name: "사격" },
      { val: "32-cart", name: "카트/루지/썰매" },
      { val: "33-track", name: "트랙 드라이빙" },
      { val: "34-canoe", name: "카누/카약" },
    ],
  },
  {
    keyName: "v-interest-general",
    label: "어떤 주종을 좋아하세요?",
    type: "multi",
    options: [
      { val: "35-soju", name: "소주" },
      { val: "36-wine", name: "와인" },
      { val: "37-mak", name: "막걸리" },
      { val: "38-beer", name: "맥주" },
      { val: "39-cocktail", name: "칵테일/위스키/보드카" },
    ],
  },
  {
    keyName: "v-interest-general",
    label: "관심있는 곳을 고르세요",
    type: "multi",
    options: [
      { val: "40-history", name: "역사" },
      { val: "41-art", name: "미술/예술" },
      { val: "42-science", name: "자연/과학" },
    ],
  },
  {
    keyName: "v-interest-general",
    label: "관심있는 것을 고르세요",
    type: "multi",
    options: [
      { val: "43-hotspring", name: "온천" },
      { val: "44-yoga", name: "요가/명상" },
      { val: "45-spa", name: "스파/마사지" },
      { val: "46-swim", name: "실내수영장" },
    ],
  },
  {
    keyName: "v-popular",
    label: "유명한 곳을 좋아하나요?",
    type: "checkbox",
  },
  {
    keyName: "v-busy",
    label: "최대한 많이 다니고 싶나요?",
    type: "checkbox",
  },
  {
    keyName: "v-insta",
    label: "인스타에 올릴 사진?",
    type: "checkbox",
  },
  {
    keyName: "v-covid",
    label: "코로나 시국?",
    type: "checkbox",
  },
];

const defaultState = {
  clusterId: "0",
  "m-bearer": "",
  "m-preset": "15",
  "m-save": "0",
  "v-date": "2021-01-06",
  "v-season": "winter",
  "v-length": 3,
  "v-alone": 0,
  "v-u24": 0,
  "v-u48": 0,
  "v-o48": 0,
  "v-kids": 0,
  "v-senior": 0,
  "v-pets": 0,
  "v-partysize": 2,
  "v-transit": 0,
  "v-interest-general": [],
  "v-popular": 0,
  "v-busy": 0,
  "v-insta": 0,
  // TODO: Remove v-budget after refining Algo
  "v-budget": 0,
  "v-covid": 0,
  "v-interest-one-pick": [""],
  "v-one-pick-id": "",
  "v-distance": 40,
  "v-stay-budget": ["best"],
  "v-stay-type": ["anything"],
  "v-depart-latitude": "37.55873348",
  "v-depart-longitude": "128.00000000",
  "v-user-id": "10",
  "v-user-stay-latitude": "",
  "v-user-stay-longitude": "",
  "v-dest-zone-keyword": "",
  "w-senior-insta-minus-eat": "-0.3",
  "w-senior-intense-minus-act": "-0.3",
  "w-popular-popular-plus-eat": "0.3",
  "w-popular-certified-plus-eat": "0.3",
  "w-popular-popular-plus-act": "0.3",
  "w-discover-popular-minus-eat": "-0.3",
  "w-discover-popular-minus-act": "-0.3",
  "w-relax-intense-minus-act": "-0.3",
  "w-instayes-insta-plus-eat": "0.3",
  "w-instayes-insta-plus-act": "0.3",
  "w-instano-insta-minus-eat": "-0.3",
  "w-spring-spring-plus-eat": "0.3",
  "w-spring-spring-plus-act": "0.3",
  "w-fall-fall-plus-eat": "0.3",
  "w-fall-fall-plus-act": "0.3",
  "w-rel-ratio-eat": "0.6",
  "w-rel-ratio-act": "0.6",
  "w-u24-prefer-plus-eat": "0.6",
  "w-u24-prefer-plus-act": "0.6",
  "w-u48-prefer-plus-eat": "0.3",
  "w-u48-prefer-plus-act": "0.3",
  "w-o48-prefer-plus-eat": "0.3",
  "w-o48-prefer-plus-act": "0.3",
  "w-dinner-bonus": "10",
  "w-one-pick-bonus": "50",
  "w-user-pick-bonus": "20",
  "w-kakao-bonus": "0.5",
  "w-google-bonus": "0.5",
  "w-alone-prefer-minus-eat": "-50",
  "w-day-one-bonus": "5",
};

const dialGroups = [
  [
    {
      keyName: "w-senior-insta-minus-eat",
      label: "인스타 -(먹)",
      type: "dial",
    },
    {
      keyName: "w-senior-intense-minus-act",
      label: "격렬한 -(할)",
      type: "dial",
    },
    {
      keyName: "w-popular-popular-plus-eat",
      label: "알려진 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-popular-certified-plus-eat",
      label: "유명매체 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-popular-popular-plus-act",
      label: "알려진 +(할)",
      type: "dial",
    },
    {
      keyName: "w-discover-popular-minus-eat",
      label: "알려진 -(먹)",
      type: "dial",
    },
    {
      keyName: "w-discover-popular-minus-act",
      label: "알려진 -(할)",
      type: "dial",
    },
    {
      keyName: "w-relax-intense-minus-act",
      label: "격렬한 -(할)",
      type: "dial",
    },
    {
      keyName: "w-instayes-insta-plus-eat",
      label: "인스타 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-instayes-insta-plus-act",
      label: "인스타 +(할)",
      type: "dial",
    },
    {
      keyName: "w-instano-insta-minus-eat",
      label: "인스타 -(먹)",
      type: "dial",
    },
    {
      keyName: "w-spring-spring-plus-eat",
      label: "봄제철 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-spring-spring-plus-act",
      label: "봄제철 +(할)",
      type: "dial",
    },
    {
      keyName: "w-fall-fall-plus-eat",
      label: "가을제철 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-fall-fall-plus-act",
      label: "가을제철 +(할)",
      type: "dial",
    },
    { keyName: "w-rel-ratio-eat", label: "선호점수 가중(먹)", type: "dial" },
    { keyName: "w-rel-ratio-act", label: "선호점수 가중(할)", type: "dial" },
  ],
  [
    {
      keyName: "w-u24-prefer-plus-eat",
      label: "u24 적합 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-u24-prefer-plus-act",
      label: "u24 적합 +(할)",
      type: "dial",
    },
    {
      keyName: "w-u48-prefer-plus-eat",
      label: "u48 적합 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-u48-prefer-plus-act",
      label: "u48 적합 +(할)",
      type: "dial",
    },
    {
      keyName: "w-o48-prefer-plus-eat",
      label: "o48 적합 +(먹)",
      type: "dial",
    },
    {
      keyName: "w-o48-prefer-plus-act",
      label: "o48 적합 +(할)",
      type: "dial",
    },
    { keyName: "w-dinner-bonus", label: "저녁 가산점", type: "dial" },
    { keyName: "w-one-pick-bonus", label: "원픽 가산점", type: "dial" },
    { keyName: "w-user-pick-bonus", label: "관심사 가산점", type: "dial" },
    { keyName: "w-kakao-bonus", label: "카카오 별점 보너스", type: "dial" },
    { keyName: "w-google-bonus", label: "구글 별점 보너스", type: "dial" },
    {
      keyName: "w-alone-prefer-minus-eat",
      label: "혼자 식당 감점",
      type: "dial",
    },
    {
      keyName: "w-day-one-bonus",
      label: "첫 날 보너스",
      type: "dial",
    },
  ],
];

const Mirani = () => {
  const [user] = useContext(UserContext);
  const [state, setState] = useState(defaultState);
  const [result, setResult] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const getProdHandler = async (e) => {
    setIsProcessing(true);
    const data = await postSurvey(state, "prod");
    setResult(data);
    setIsProcessing(false);
  };

  const getDevHandler = async (e) => {
    setIsProcessing(true);
    const data = await postSurvey(state, "dev");
    setResult(data);
    setIsProcessing(false);
  };

  return !user.token ? (
    <div>로그인이 필요합니다.</div>
  ) : (
    <div className="flex-auto overflow-y-auto">
      <div className="h-1/2 flex flex-col flex-wrap p-2 border-b">
        {surveyList.map((props, index) =>
          SurveyBox({ ...props, state, setState, index })
        )}
      </div>
      <div className="h-20 border-b"></div>
      <div className="grid grid-cols-17 text-sm text-center">
        <div className="col-span-2 bg-green-100">장년(50세 이상)</div>
        <div className="col-span-3 bg-green-200">검증된</div>
        <div className="col-span-2 bg-green-300">참신함</div>
        <div className="col-span-1 bg-green-200">느긋</div>
        <div className="col-span-2 bg-green-100">인스타 선호</div>
        <div className="col-span-1 bg-green-200">인스타 불호</div>
        <div className="col-span-2 bg-green-300">봄</div>
        <div className="col-span-2 bg-green-200">가을</div>
        <div className="col-span-2 bg-green-100">선호점수 가중치</div>
        {dialGroups[0].map((props, index) =>
          SurveyBox({ ...props, state, setState, index })
        )}
      </div>
      <div className="grid grid-cols-17 text-sm text-center">
        <div className="col-span-full bg-green-200">기타</div>
        {dialGroups[1].map((props, index) =>
          SurveyBox({ ...props, state, setState, index })
        )}
      </div>
      <div className="flex items-center gap-x-4 p-4 bg-green-100">
        <button onClick={getProdHandler} className="border bg-red-200 w-40 p-1">
          Get! (Prod)
        </button>
        <button onClick={getDevHandler} className="border bg-blue-200 w-40 p-1">
          Get! (Dev)
        </button>
        <label>Show all</label>
        <ToggleButton
          value={showAll}
          onToggle={() => {
            setShowAll(!showAll);
          }}
        />
      </div>
      <div>
        <OutputTable
          candidates={result}
          showAll={showAll}
          isProcessing={isProcessing}
        />
      </div>
    </div>
  );
};

export default Mirani;
