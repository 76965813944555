import React from "react";
import SalesOrderTable from "./SalesOrderTable";

const SalesOrders = () => {
  return (
    <div>
      <SalesOrderTable />
    </div>
  );
};

export default SalesOrders;
