import React from "react";
import { UserContext } from "../../contexts/user";
import { useContext } from "react";

const Welcome = () => {
  const [user] = useContext(UserContext);
  return (
    <div className="w-full text-center mt-20">
      <p className="text-5xl text-blue-600 font-bold">반가워요!</p>
      <p className="text-5xl text-blue-600 font-bold">
        {user.name ? ` ${user.name}님!` : ` 로그인을 해주세요!`}
      </p>
    </div>
  );
};

export default Welcome;
