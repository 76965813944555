import React, { createContext, useReducer } from "react";

import Axios from "axios";

const initialState = {
  token: null,
  name: null,
  role: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNIN":
      if (action.token) {
        window.localStorage.setItem("token", action.token);
        window.localStorage.setItem("name", action.name);
        window.localStorage.setItem("role", action.role);

        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${action.token}`;

        console.log("success");
        return {
          ...state,
          token: action.token,
          name: action.name,
          role: action.role,
        };
      } else {
        return state;
      }
    case "SIGNOUT":
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("name");
      window.localStorage.removeItem("role");

      return initialState;

    default:
      return state;
  }
};
const UserContext = createContext([initialState, () => initialState]);

const UserProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
