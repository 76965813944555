import React, { useCallback, useEffect, useState } from "react";

import Modal from "react-modal";
import { postItnFromMirani } from "../../api/Itns";
import stableStringify from "json-stable-stringify";
import { toast } from "react-toastify";

const OutputTable = ({ candidates, showAll, isProcessing }) => {
  const [selected, setSelected] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itnList, setItnList] = useState([]);
  const [metadataList, setMetadataList] = useState([]);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const saveHandler = async (result) => {
    const resp = await postItnFromMirani(result.itinerary);

    if (resp) {
      toast.success(`일정표 생성: ${resp.id}`);
    } else {
      toast.error("일정표 생성에 실패했습니다.");
    }
  };

  const getLists = useCallback(() => {
    const newItnList = [];
    const newMetadataList = [];

    candidates
      .filter(({ result }) => {
        return result !== undefined;
      })
      .sort((a, b) => (a.totalScore > b.totalScore ? -1 : 1))
      .forEach(({ surveyInput, result, totalScore, destinations, preset }) => {
        newItnList.push(result.itinerary);
        newMetadataList.push({
          preset,
          totalScore,
          surveyInput,
          destinations,
          result,
        });
      });

    return {
      newItnList,
      newMetadataList,
    };
  }, [candidates]);

  useEffect(() => {
    const { newItnList, newMetadataList } = getLists();

    setItnList(newItnList ? newItnList : []);
    setMetadataList(newMetadataList ? newMetadataList : []);
  }, [getLists]);

  // TODO: Handle multiple responses when ready
  return isProcessing ? (
    <div>로딩 중입니다.</div>
  ) : itnList.length ? (
    <div className="w-full overflow-x-auto">
      {itnList.map((itn, index) => {
        return (
          <button
            key={index}
            className={`border rounded-xl p-2 mx-2 ${
              // index !== 0
              selected !== index
                ? "bg-green-700 text-white"
                : "bg-yellow-400 text-black"
            }
            ${index !== 0 ? "w-12" : null}`}
            onClick={() => {
              setSelected(index);
            }}
          >
            {index !== 0 ? index : "BEST"}
          </button>
        );
      })}
      <button
        className="font-bold border rounded-xl bg-yellow-700 text-white p-2 mx-2"
        onClick={() => {
          openModal();
        }}
      >
        SHOW SURVEY INPUT
      </button>
      <div className="flex items-center gap-x-4 w-full bg-blue-100 p-4">
        <span className="font-bold">PRESET: </span>
        <span>{metadataList[selected].preset.join(" ")}</span>
        <span className="font-bold">TOTAL SCORE: </span>
        <span>{metadataList[selected].totalScore}</span>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
          <div className="w-full bg-gray-800 text-white">
            <pre>
              {stableStringify(metadataList[selected].surveyInput, {
                space: "  ",
              })}
            </pre>
          </div>
        </Modal>

        <button
          className="font-bold border rounded-xl bg-yellow-700 text-white p-2 mx-2"
          onClick={() => {
            saveHandler(metadataList[selected].result);
          }}
        >
          Save
        </button>
      </div>
      <table className="text-sm w-max table-auto">
        <thead>
          <tr>
            <th>day</th>
            <th>slot</th>
            <th>rank</th>
            <th>zone</th>
            <th>id</th>
            <th>name</th>
            <th>description</th>
            <th>scoreTotal</th>
            <th>scoreRating</th>
            <th>scorePref</th>
            <th>daySlot</th>
            <th>kakaoRating</th>
            <th>googleRating</th>
            <th>price</th>
            <th>filtered</th>
            <th>filterNames</th>
            <th>bonusSenior</th>
            <th>bonusPopular</th>
            <th>bonusBusy</th>
            <th>bonusInsta</th>
            <th>userPick</th>
            <th>onePickCat</th>
            <th>onePickId</th>
            <th>dinnerBonus</th>
            <th>catActX</th>
            <th>catRestX</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(itnList[selected])
            .filter(([, { rank }]) => {
              return showAll || rank === "main" || rank === "alt";
            })
            .sort(([index]) => index)
            .map(([index, poi]) => {
              return (
                <tr
                  className={`text-sm font-light text-center ${
                    poi.rank === "main" && "bg-green-200"
                  } ${poi.rank === "alt" && "bg-yellow-100"}`}
                  key={index}
                >
                  <td>{poi.day}</td>
                  <td>{poi.slot}</td>
                  <td>{poi.rank}</td>
                  <td>{poi.zone}</td>
                  <td>{poi.id}</td>
                  <td>{poi.name}</td>
                  <td>{poi.description}</td>
                  <td>{poi.scoreTotal}</td>
                  <td>{poi.scoreRating}</td>
                  <td>{poi.scorePref}</td>
                  <td>{poi.daySlot}</td>
                  <td>{poi.kakaoRating}</td>
                  <td>{poi.googleRating}</td>
                  <td>{poi.price}</td>
                  <td>{poi.filtered}</td>
                  <td>{poi.filterNames}</td>
                  <td>{poi.bonusSenior}</td>
                  <td>{poi.bonusPopular}</td>
                  <td>{poi.bonusBusy}</td>
                  <td>{poi.bonusInsta}</td>
                  <td>{poi.userPick}</td>
                  <td>{poi.onePickCat}</td>
                  <td>{poi.onePickId}</td>
                  <td>{poi.dinnerBonus}</td>
                  <td>{poi.catActX}</td>
                  <td>{poi.catRestX}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default OutputTable;
