import Axios from "axios";

const getWeeklyArticles = async () => {
  try {
    const { data } = await Axios.get(`/v2/weekly_articles`);

    console.log(data);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const createWeeklyArticle = async () => {
  try {
    const { data } = await Axios.post(`/v2/weekly_articles`, {
      seg_id: 0,
      type: "void",
      is_active: false,
      content: {},
    });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateWeeklyArticle = async (id, obj) => {
  try {
    const { data } = await Axios.put(`/v2/weekly_articles/${id}`, obj);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const sendPushNotification = async (body) => {
  try {
    const { data } = await Axios.post(
      `https://gnash-prod.stori.city/v2/weekly/noti/push`,
      // `http://localhost:3003/v2/weekly/noti/push`,
      body
    );

    return data;
  } catch (e) {
    console.error("sendPushNotification error response", e.response);
    if (e.response && e.response.data && e.response.data.msg) {
      alert(e.response.data.msg);
    }
    return null;
  }
};

export {
  getWeeklyArticles,
  createWeeklyArticle,
  updateWeeklyArticle,
  sendPushNotification,
};
