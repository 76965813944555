import Axios from "axios";

const getTransports = async (poiIds) => {
  const param = poiIds.join(",");

  try {
    const { data } = await Axios.get(`/v2/transports/list?poiIds=${param}`);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { getTransports };
