import Axios from "axios";

const updateDelivery = async (id, itineraryId) => {
  try {
    const { data } = await Axios.put(`/v2/deliveries/${id}`, { itineraryId });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const confirmDelivery = async (id) => {
  try {
    const { data } = await Axios.put(`/v2/deliveries/${id}/confirm`);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const prepareDelivery = async (id) => {
  try {
    const { data } = await Axios.put(`/v2/deliveries/${id}/prepare`);

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { updateDelivery, confirmDelivery, prepareDelivery };
