import React, { useContext } from "react";
import { createZone, getZones, updateZone } from "../../api/Zones";

import ToggleButton from "react-toggle-button";
import { WizardContext } from "../../contexts/wizard";
import { toast } from "react-toastify";

const ControlBar = () => {
  const [ctx, dispatch] = useContext(WizardContext);
  const { mode, editingZonePath, editingZoneId, showExistingZones } = ctx;

  const toggleHandler = () => {
    if (
      window.confirm("작업 중인 정보가 초기화 됩니다. 계속 진행하시겠습니까?")
    ) {
      dispatch({ type: "TOGGLE_MODE" });
    }
  };

  const saveHandler = async () => {
    if (!window.confirm("Zone을 저장하시겠습니까?")) return;

    const [centerLat, centerLng] = editingZonePath
      .reduce(
        (acc, cur) => {
          return [
            [...acc[0], cur[0]],
            [...acc[1], cur[1]],
          ];
        },
        [[], []]
      )
      .map((nums) => {
        const max = Math.max(...nums);
        const min = Math.min(...nums);

        return (max + min) / 2;
      });

    if (editingZoneId === undefined) {
      await createZone({
        centerLat,
        centerLng,
        coords: editingZonePath,
      });
    } else {
      await updateZone(editingZoneId, {
        centerLat,
        centerLng,
        coords: editingZonePath,
      });
    }

    dispatch({ type: "UPDATE_EDITING_ZONE_PATH", value: [] });
    dispatch({ type: "UPDATE_EDITING_ZONE_ID", value: undefined });

    const zoneData = await getZones();
    const zoneMap = {};
    zoneData.results.forEach((zone) => {
      zoneMap[zone.id] = zone;
    });

    dispatch({ type: "UPDATE_ZONES", value: zoneMap });

    toast.info("위자드가 준비됐습니다 :)");
  };

  const clearHandler = () => {
    dispatch({ type: "UPDATE_EDITING_ZONE_PATH", value: [] });
    dispatch({ type: "UPDATE_EDITING_ZONE_ID", value: undefined });
  };

  const showZonesHandler = () => {
    dispatch({ type: "TOGGLE_SHOW_EXISTING_ZONES" });
  };

  return (
    <div className="h-10 flex items-center justify-around gap-x-2 px-2">
      <div className="flex gap-x-1 flex-1">
        <span className="text-xs">Zone Editor</span>
        <ToggleButton value={mode === "ZONE_EDITOR"} onToggle={toggleHandler} />
      </div>
      <div>
        {mode === "ZONE_EDITOR" && (
          <div className="flex gap-x-2 justify-between">
            <button
              className="text-xs border p-2 rounded-lg bg-green-300"
              onClick={showZonesHandler}
            >
              {showExistingZones ? "Hide Zones" : "Show Zones"}
            </button>
            <button
              className="text-xs border p-2 px-4 rounded-lg bg-green-400"
              onClick={clearHandler}
            >
              New Zone
            </button>
            <button
              className="text-xs border p-2 px-4 rounded-lg bg-green-500"
              onClick={saveHandler}
            >
              {editingZoneId === undefined ? "Create" : "Update"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ControlBar;
