import React, { useEffect, useState } from "react";
import { getOpenOrders, updateOrderDelivery } from "../../api/Orders";

import UpdateModalField from "./UpdateModalField";
import { toast } from "react-toastify";

const fields = [
  {
    type: "static",
    name: "userName",
    label: "고객명",
  },
  {
    type: "static",
    name: "surveyId",
    label: "Survey ID",
  },
  {
    type: "static",
    name: "orderId",
    label: "Order ID",
  },
  {
    type: "static",
    name: "deliveryId",
    label: "Delivery ID",
  },
  {
    type: "integer",
    name: "itineraryId",
    label: "Itinerary ID",
  },
  {
    type: "integer",
    name: "highlight1Id",
    label: "highlight 1 ID",
  },
  {
    type: "integer",
    name: "highlight2Id",
    label: "highlight 2 ID",
  },
  {
    type: "integer",
    name: "highlight3Id",
    label: "highlight 3 ID",
  },
  {
    type: "textarea",
    name: "descriptions",
    label: "Descriptions",
  },
  {
    type: "text",
    name: "tags",
    label: "tags",
  },
  {
    type: "text",
    name: "location",
    label: "location",
  },
];

const UpdateModal = (props) => {
  const [state, setState] = useState({});
  const {
    userName,
    surveyId,
    orderId,
    deliveryId,
    itineraryId,
    highlights,
    descriptions,
    tags,
    location,
    setOrders,
    closeEditModal,
  } = props;

  useEffect(() => {
    setState({
      userName,
      surveyId,
      orderId,
      deliveryId,
      itineraryId,
      highlight1Id: highlights && highlights[0],
      highlight2Id: highlights && highlights[1],
      highlight3Id: highlights && highlights[2],
      descriptions,
      tags,
      location,
      setOrders,
    });
  }, [userName]);

  const updateHandler = async () => {
    console.log(state);
    const { surveyId } = state;

    const updated = await updateOrderDelivery(surveyId, {
      itineraryId: state.itineraryId,
      location: state.location,
      tags: state.tags,
      highlight1Id: state.highlight1Id,
      highlight2Id: state.highlight2Id,
      highlight3Id: state.highlight3Id,
      descriptions: state.descriptions,
    });

    if (updated) {
      getOpenOrders().then(setOrders);
      toast.success(`Survey ID: ${surveyId} 주문 업데이트에 성공했습니다.`);
      closeEditModal();
    } else {
      toast.error("업데이트에 실패했습니다.");
    }
  };

  return state ? (
    <div>
      {fields.map(({ type, name, label }) => (
        <UpdateModalField
          type={type}
          name={name}
          label={label}
          state={state}
          setState={setState}
        />
      ))}
      <div>
        <button
          className="border bg-green-200 p-2"
          onClick={() => updateHandler()}
        >
          업데이트
        </button>
      </div>
    </div>
  ) : null;
};

export default UpdateModal;
