import { Draggable } from "react-beautiful-dnd";
import React from "react";
import SelectedPoiAttrType from "../../Types/SelectedPoiAttrType";
import { getBgColor } from "../../util/Util";
import { toast } from "react-toastify";

const Card = ({
  poi,
  id,
  selectPoi,
  index,
  isCourseCard,
  isNewday,
  isSub,
  toggleCardAttr,
  transportMin,
}) => {
  const clickHandler = (e) => {
    selectPoi(poi);
    console.log(index, isNewday, isSub);
  };

  const checkHandler = (e) => {
    e.stopPropagation();
    const type = e.currentTarget.name;
    if (isNewday || isSub) {
      toggleCardAttr(index, SelectedPoiAttrType.normal);
    } else if (type === SelectedPoiAttrType.newday) {
      toggleCardAttr(index, SelectedPoiAttrType.newday);
    } else if (type === SelectedPoiAttrType.sub) {
      toggleCardAttr(index, SelectedPoiAttrType.sub);
    } else {
      toast.error("문제가 발생했습니다. 관리자에게 문의하세요.");
    }
  };

  return (
    <Draggable
      key={`${id}`}
      draggableId={`${id}`}
      index={index}
      disableInteractiveElementBlocking={true}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`rounded-lg m-2 bg-${getBgColor(poi)}-400 ${
              isSub ? "ml-6" : null
            } border text-xs`}
            onClick={clickHandler}
          >
            <div className="flex">
              <div className="flex-1 flex items-center p-2 break-all">
                <span>{poi.name}</span>
              </div>
            </div>
            {isCourseCard && index > 0 ? (
              <div
                className={`grid grid-cols-7 items-center rounded-md px-4 ${
                  isSub ? "bg-gray-300" : "bg-white"
                } ${isNewday ? "bg-gray-600" : null}`}
              >
                {isSub ? null : (
                  <div className="flex justify-start items-center gap-x-2 col-span-3">
                    <input
                      type="checkbox"
                      name={SelectedPoiAttrType.newday}
                      id={`${id}-newday`}
                      checked={isNewday}
                      disabled={isSub}
                      onClick={(e) => e.stopPropagation()}
                      onChange={checkHandler}
                    />
                    <label htmlFor={`${id}-newday`}>New day</label>
                  </div>
                )}
                {isNewday ? null : (
                  <div className="col-start-4 flex justify-start items-center gap-x-2 col-span-3">
                    <input
                      type="checkbox"
                      name={SelectedPoiAttrType.sub}
                      id={`${id}-sub`}
                      checked={isSub}
                      disabled={isNewday}
                      onClick={(e) => e.stopPropagation()}
                      onChange={checkHandler}
                    />
                    <label htmlFor={`${id}-sub`}>Sub</label>
                  </div>
                )}
                {isSub ? null : (
                  <div className="col-start-7">
                    <span>{transportMin}분</span>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </React.Fragment>
      )}
    </Draggable>
  );
};

export default Card;
