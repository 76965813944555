import INPUT_TYPE from "./InputType";

const POI_OBJ = {
  id: {
    label: "ID",
    type: INPUT_TYPE.immutable,
    initialValue: undefined,
  },
  poiTypeId: {
    label: "종류",
    type: INPUT_TYPE.dropdown,
    options: [
      ["숙소", 4],
      ["액티비티", 3],
      ["식당", 6],
      ["관광스팟", 2],
      ["카페", 7],
      ["대중교통거점", 8],
      ["비활성화대중교통거점", 9],
      ["바/주점", 10],
    ],
    required: true,
    initialValue: ["", ""],
  },
  name: {
    label: "제목",
    required: true,
    initialValue: "",
  },
  mainImageUrl: {
    label: "메인이미지",
    initialValue: "",
  },
  area: {
    label: "지역",
    required: true,
    type: INPUT_TYPE.area,
    initialValue: " ",
  },
  description: {
    label: "한줄설명",
    initialValue: "",
  },
  address: {
    label: "주소",
    initialValue: "",
  },
  latitude: {
    label: "위도",
    type: INPUT_TYPE.decimal,
    initialValue: "",
  },
  longitude: {
    label: "경도",
    type: INPUT_TYPE.decimal,
    initialValue: "",
  },
  grade: {
    label: "등급",
    type: INPUT_TYPE.dropdown,
    options: [
      ["1군", "1군"],
      ["2군", "2군"],
      ["벤치", "벤치"],
      ["무덤", "무덤"],
    ],
    initialValue: ["", ""],
  },
  websiteUrl: {
    label: "웹사이트링크",
    initialValue: "",
  },
  kakaoUrl: {
    label: "카카오링크",
    initialValue: "",
  },
  googleUrl: {
    label: "구글링크",
    initialValue: "",
  },
  naverUrl: {
    label: "네이버링크",
    initialValue: "",
  },
  stayfolioUrl: {
    label: "스테이폴리오링크",
    initialValue: "",
  },
  mangoplateUrl: {
    label: "망고플레이트링크",
    initialValue: "",
  },
  reservationUrl: {
    label: "예약링크",
    initialValue: "",
  },
  telephone: {
    label: "전화번호",
    width: 100,
    initialValue: "",
  },
  accommodationType: {
    label: "숙소 카테고리",
    type: INPUT_TYPE.dropdown,
    options: [
      ["호텔/모텔", "호텔/모텔"],
      ["리조트", "리조트"],
      ["독채/펜션", "독채/펜션"],
      ["글램핑/캠핑", "글램핑/캠핑"],
      ["게스트하우스", "게스트하우스"],
      ["Airbnb", "Airbnb"],
      ["기타", "기타"],
    ],
    initialValue: ["", ""],
  },
  activityType: {
    label: "액티비티 카테고리",
    type: INPUT_TYPE.dropdown,
    options: [
      ["가벼운 산책", "가벼운 산책"],
      ["가벼운 등산", "가벼운 등산"],
      ["본격 등산", "본격 등산"],
      ["스키/보드", "스키/보드"],
      ["골프", "골프"],
      ["자전거", "자전거"],
      ["레일바이크", "레일바이크"],
      ["패러글라이딩", "패러글라이딩"],
      ["승마", "승마"],
      ["워터파크", "워터파크"],
      ["서핑", "서핑"],
      ["사격", "사격"],
      ["카트/루지/썰매", "카트/루지/썰매"],
      ["요트", "요트"],
      ["낚시", "낚시"],
      ["스쿠버", "스쿠버"],
      ["짚라인", "짚라인"],
      ["지역축제", "지역축제"],
      ["미술/예술관", "미술/예술관"],
      ["자연과학전시관", "자연과학전시관"],
      ["지역상점", "지역상점"],
      ["온천", "온천"],
      ["요가명상", "요가명상"],
      ["스파마사지", "스파마사지"],
      ["실내수영장", "실내수영장"],
      ["가이드투어/클래스", "가이드투어/클래스"],
      ["템플스테이", "템플스테이"],
      ["야간액티비티", "야간액티비티"],
      ["스냅촬영", "스냅촬영"],
      ["동물/이색체험", "동물/이색체험"],
      ["테마파크/아쿠아리움", "테마파크/아쿠아리움"],
      ["경치관람", "경치관람"],
      ["드라이브코스", "드라이브코스"],
      ["수상레저", "수상레저"],
      ["카누/카약", "카누/카약"],
      ["스쿠버다이빙", "스쿠버다이빙"],
      ["꽃놀이스팟", "꽃놀이스팟"],
      ["수목원/정원", "수목원/정원"],
      ["가이드투어/산책", "가이드투어/산책"],
      ["격렬한액티비티", "격렬한액티비티"],
      ["경/중강도액티비티", "경/중강도액티비티"],
      ["예술/문화", "예술/문화"],
      ["커뮤니티", "커뮤니티"],
      ["휴식", "휴식"],
      ["이동/편의", "이동/편의"],
      ["어트랙션", "어트랙션"],
      ["기타", "기타"],
    ],
    initialValue: ["", ""],
  },
  restaurantType: {
    label: "식당 카테고리",
    type: INPUT_TYPE.dropdown,
    options: [
      ["한식", "한식"],
      ["양식", "양식"],
      ["카페", "카페"],
      ["일식", "일식"],
      ["중식", "중식"],
      ["기타", "기타"],
    ],
    initialValue: ["", ""],
  },
  tourspotType: {
    label: "관광지 카테고리",
    type: INPUT_TYPE.dropdown,
    options: [
      ["가벼운 산책", "가벼운 산책"],
      ["가벼운 등산", "가벼운 등산"],
      ["본격 등산", "본격 등산"],
      ["스키/보드", "스키/보드"],
      ["골프", "골프"],
      ["자전거", "자전거"],
      ["레일바이크", "레일바이크"],
      ["패러글라이딩", "패러글라이딩"],
      ["승마", "승마"],
      ["워터파크", "워터파크"],
      ["서핑", "서핑"],
      ["사격", "사격"],
      ["카트/루지/썰매", "카트/루지/썰매"],
      ["요트", "요트"],
      ["낚시", "낚시"],
      ["스쿠버", "스쿠버"],
      ["짚라인", "짚라인"],
      ["지역축제", "지역축제"],
      ["미술/예술관", "미술/예술관"],
      ["자연과학전시관", "자연과학전시관"],
      ["지역상점", "지역상점"],
      ["온천", "온천"],
      ["요가명상", "요가명상"],
      ["스파마사지", "스파마사지"],
      ["실내수영장", "실내수영장"],
      ["가이드투어/클래스", "가이드투어/클래스"],
      ["템플스테이", "템플스테이"],
      ["야간액티비티", "야간액티비티"],
      ["스냅촬영", "스냅촬영"],
      ["동물/이색체험", "동물/이색체험"],
      ["테마파크/아쿠아리움", "테마파크/아쿠아리움"],
      ["경치관람", "경치관람"],
      ["드라이브코스", "드라이브코스"],
      ["수상레저", "수상레저"],
      ["카누/카약", "카누/카약"],
      ["스쿠버다이빙", "스쿠버다이빙"],
      ["꽃놀이스팟", "꽃놀이스팟"],
      ["수목원/정원", "수목원/정원"],
      ["유적/문화재", "유적/문화재"],
      ["문화/예술", "문화/예술"],
      ["자연", "자연"],
      ["어트랙션", "어트랙션"],
      ["휴양시설", "휴양시설"],
      ["로컬", "로컬"],
      ["기타", "기타"],
    ],
    initialValue: ["", ""],
  },
  priceLevel: {
    label: "가격대",
    type: INPUT_TYPE.dropdown,
    options: [
      ["미분류", 0],
      ["$", 1],
      ["$$", 2],
      ["$$$", 3],
      ["$$$$", 4],
    ],
    initialValue: ["", ""],
  },
  openHours: {
    label: "영업시간",
    initialValue: "",
  },
  googleRating: {
    label: "구글평점",
    type: INPUT_TYPE.fiveRating,
    initialValue: "",
  },
  kakaoRating: {
    label: "카카오평점",
    type: INPUT_TYPE.fiveRating,
    initialValue: "",
  },
  naverRating: {
    label: "네이버평점",
    type: INPUT_TYPE.fiveRating,
    initialValue: "",
  },
  trivagoRating: {
    label: "트리바고",
    type: INPUT_TYPE.tenRating,
    initialValue: "",
  },
  hotelscomRating: {
    label: "호텔스닷컴",
    type: INPUT_TYPE.tenRating,
    initialValue: "",
  },
  menu: {
    label: "메뉴",
    type: INPUT_TYPE.menu,
    initialValue: "",
  },
  source: {
    label: "출처",
    initialValue: "",
  },
  canPark: {
    label: "주차가능",
    type: INPUT_TYPE.dropdown,
    options: [
      ["가능", true],
      ["불가", false],
    ],
    initialValue: ["", ""],
  },
  canKids: {
    label: "어린이가능",
    type: INPUT_TYPE.dropdown,
    options: [
      ["가능", true],
      ["불가", false],
    ],
    initialValue: ["", ""],
  },
  isNew: {
    type: INPUT_TYPE.hidden,
    label: "새POI구분",
    initialValue: true,
  },
};

export default POI_OBJ;
