import React from "react";

const UpdateModalField = ({ type, name, label, state, setState }) => {
  const changeHandler = (e) => {
    setState({ ...state, [name]: e.target.value });
  };

  const radioChangeHandler = (e) => {
    setState({ ...state, [name]: e.target.id.includes("true") });
  };

  switch (type) {
    case "static":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>
          <div>{state[name]}</div>
        </div>
      );
    case "integer":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>

          <input
            type="number"
            className="border p-2"
            step="1"
            min="0"
            value={state[name]}
            onChange={changeHandler}
          />
        </div>
      );
    case "textarea":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>

          <textarea
            className="border p-2"
            value={state[name]}
            onChange={changeHandler}
          />
        </div>
      );
    case "text":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>

          <input
            className="border p-2"
            value={state[name]}
            onChange={changeHandler}
          />
        </div>
      );
    case "boolean":
      return (
        <div className="flex my-auto" key={name}>
          <div>{label}: </div>

          <input
            id={`${name}-true`}
            type="radio"
            className="border p-2"
            name={name}
            checked={state[name] == true}
            onChange={radioChangeHandler}
          />
          <label htmlFor={`${name}-true`} className="mr-4">
            True
          </label>
          <input
            id={`${name}-false`}
            type="radio"
            className="border p-2"
            name={name}
            checked={state[name] == false}
            onChange={radioChangeHandler}
          />
          <label htmlFor={`${name}-false`}>False</label>
        </div>
      );

    case "insta":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>
          <div>
            <div>
              <div>Insta1</div>
              <div className="flex">
                <label htmlFor="insta1-link">Link</label>
                <input className="border p-2" id="insta1-link"></input>
                <label htmlFor="insta1-author-name">Author</label>
                <input className="border p-2" id="insta1-author-name"></input>
                <label htmlFor="insta1-thumbnail-url">Thumbnail</label>
                <input className="border p-2" id="insta1-thumbnail-url"></input>
              </div>
            </div>
            <div>
              <div>Insta2</div>
              <div className="flex">
                <label htmlFor="insta2-link">Link</label>
                <input className="border p-2" id="insta2-link"></input>
                <label htmlFor="insta2-author-name">Author</label>
                <input className="border p-2" id="insta2-author-name"></input>
                <label htmlFor="insta2-thumbnail-url">Thumbnail</label>
                <input className="border p-2" id="insta2-thumbnail-url"></input>
              </div>
            </div>
            <div>
              <div>Insta3</div>
              <div className="flex">
                <label htmlFor="insta3-link">Link</label>
                <input className="border p-2" id="insta3-link"></input>
                <label htmlFor="insta3-author-name">Author</label>
                <input className="border p-2" id="insta3-author-name"></input>
                <label htmlFor="insta3-thumbnail-url">Thumbnail</label>
                <input className="border p-2" id="insta3-thumbnail-url"></input>
              </div>
            </div>
            <div>
              <div>Insta4</div>
              <div className="flex">
                <label htmlFor="insta4-link">Link</label>
                <input className="border p-2" id="insta4-link"></input>
                <label htmlFor="insta4-author-name">Author</label>
                <input className="border p-2" id="insta4-author-name"></input>
                <label htmlFor="insta4-thumbnail-url">Thumbnail</label>
                <input className="border p-2" id="insta4-thumbnail-url"></input>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return <div>No</div>;
  }
};

export default UpdateModalField;
