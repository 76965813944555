import React from "react";

const Fallback = () => {
  return (
    <div>
      <span>Please sign in first.</span>
    </div>
  );
};

export default Fallback;
