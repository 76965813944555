import React, { useEffect, useState } from "react";
import {
  getWeeklyArticles,
  updateWeeklyArticle,
} from "../../api/WeeklyArticles";

import UpdateModalField from "./UpdateModalField";
import { toast } from "react-toastify";

const fields = [
  // COMMON_FIELDS
  { type: "static", name: "for_common", label: "COMMON FIELDS" },
  {
    type: "static",
    name: "id",
    label: "ID",
  },
  {
    type: "boolean",
    name: "is_active",
    label: "Is Active",
  },
  {
    type: "text",
    name: "type",
    label: "Type",
  },
  {
    type: "integer",
    name: "seg_id",
    label: "Seg ID",
  },
  {
    type: "text",
    name: "description",
    label: "Description",
  },
  { type: "text", name: "period", label: "Period" },

  // ITIN FIELDS
  { type: "static", name: "for_common", label: "ITIN FIELDS" },
  {
    type: "integer",
    name: "itinerary_id",
    label: "Itinerary ID",
  },
  {
    type: "integer",
    name: "survey_id",
    label: "Survey ID",
  },
  {
    type: "text",
    name: "location",
    label: "Location",
  },
  {
    type: "text",
    name: "area",
    label: "Area",
  },
  {
    type: "integer",
    name: "length",
    label: "Length",
  },
  {
    type: "text",
    name: "sub_title",
    label: "Sub title",
  },
  {
    type: "text",
    name: "main_image_url",
    label: "Main IMG url",
  },

  // POI FIELDS
  { type: "static", name: "for_common", label: "POI FIELDS" },
  {
    type: "integer",
    name: "poi_id",
    label: "POI ID",
  },
  {
    type: "text",
    name: "area_small",
    label: "Area small",
  },
  {
    type: "text",
    name: "value",
    label: "Value",
  },
  {
    type: "text",
    name: "show_name",
    label: "show_name",
  },
  {
    type: "text",
    name: "title",
    label: "Title",
  },
  {
    type: "textarea",
    name: "description1",
    label: "Description 1",
  },
  {
    type: "textarea",
    name: "description2",
    label: "Description 2",
  },
  {
    type: "text",
    name: "insta1_link",
    label: "Insta1 link",
  },
  {
    type: "text",
    name: "insta1_author",
    label: "Insta1 author",
  },
  {
    type: "text",
    name: "insta1_thumbnail",
    label: "Insta1 thumbnail",
  },
  {
    type: "text",
    name: "insta2_link",
    label: "Insta2 link",
  },
  {
    type: "text",
    name: "insta2_author",
    label: "Insta2 author",
  },
  {
    type: "text",
    name: "insta2_thumbnail",
    label: "Insta2 thumbnail",
  },
  {
    type: "text",
    name: "insta3_link",
    label: "Insta3 link",
  },
  {
    type: "text",
    name: "insta3_author",
    label: "Insta3 author",
  },
  {
    type: "text",
    name: "insta3_thumbnail",
    label: "Insta3 thumbnail",
  },
  {
    type: "text",
    name: "insta4_link",
    label: "Insta4 link",
  },
  {
    type: "text",
    name: "insta4_author",
    label: "Insta4 author",
  },
  {
    type: "text",
    name: "insta4_thumbnail",
    label: "Insta4 thumbnail",
  },
];

const UpdateModal = (props) => {
  const [state, setState] = useState({});
  const {
    id,
    is_active,
    type,
    seg_id,
    area,
    title,
    value,
    length,
    poi_id,
    period,
    location,
    sub_title,
    show_name,
    survey_id,
    area_small,
    description,
    description1,
    description2,
    itinerary_id,
    main_image_url,
    insta1_link,
    insta1_author,
    insta1_thumbnail,
    insta2_link,
    insta2_author,
    insta2_thumbnail,
    insta3_link,
    insta3_author,
    insta3_thumbnail,
    insta4_link,
    insta4_author,
    insta4_thumbnail,
    setArticles,
    closeEditModal,
  } = props;

  useEffect(() => {
    setState({
      id,
      is_active,
      type,
      seg_id,
      area,
      title,
      value,
      length,
      poi_id,
      period,
      location,
      sub_title,
      show_name,
      survey_id,
      area_small,
      description,
      description1,
      description2,
      itinerary_id,
      main_image_url,
      insta1_link,
      insta1_author,
      insta1_thumbnail,
      insta2_link,
      insta2_author,
      insta2_thumbnail,
      insta3_link,
      insta3_author,
      insta3_thumbnail,
      insta4_link,
      insta4_author,
      insta4_thumbnail,
    });
  }, [id]);

  const updateHandler = async () => {
    console.log(state);
    const { id } = state;
    const updated = await updateWeeklyArticle(id, {
      is_active: state.is_active,
      type: state.type,
      seg_id: state.seg_id,
      content: {
        description: state.description,
        period: state.period,

        poi_id: state.poi_id,
        area_small: state.area_small,
        value: state.value,
        show_name: state.show_name,
        title: state.title,
        description1: state.description1,
        description2: state.description2,
        insta: [
          {
            link: state.insta1_link,
            author_name: state.insta1_author,
            thumbnail_url: state.insta1_thumbnail,
          },
          {
            link: state.insta2_link,
            author_name: state.insta2_author,
            thumbnail_url: state.insta2_thumbnail,
          },
          {
            link: state.insta3_link,
            author_name: state.insta3_author,
            thumbnail_url: state.insta3_thumbnail,
          },
          {
            link: state.insta4_link,
            author_name: state.insta4_author,
            thumbnail_url: state.insta4_thumbnail,
          },
        ],

        itinerary_id: state.itinerary_id,
        survey_id: state.survey_id,
        location: state.location,
        area: state.area,
        length: state.length,
        sub_title: state.sub_title,
        main_image_url: state.main_image_url,
      },
    });

    if (updated) {
      getWeeklyArticles().then(setArticles);
      toast.success(`WeeklyArticle ID: ${id} 주문 업데이트에 성공했습니다.`);
      closeEditModal();
    } else {
      toast.error("업데이트에 실패했습니다.");
    }
  };

  return state ? (
    <div>
      {fields.map(({ type, name, label }, index) => (
        <UpdateModalField
          key={index}
          type={type}
          name={name}
          label={label}
          state={state}
          setState={setState}
        />
      ))}
      <div>
        <button
          className="border bg-green-200 p-2"
          onClick={() => updateHandler()}
        >
          업데이트
        </button>
      </div>
    </div>
  ) : null;
};

export default UpdateModal;
