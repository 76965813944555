import React, { useState } from "react";

import { searchPoiImages } from "../../api/Pois";

const ImageSearcher = () => {
  const [searchText, setSearchText] = useState("");
  const [pois, setPois] = useState([]);

  const changeText = (event) => setSearchText(event.target.value);

  const searchPois = async (name) => {
    const pois = await searchPoiImages(name);

    setPois(pois);
  };

  return (
    <div>
      <div>
        <input
          type="text"
          onChange={changeText}
          className="border-gray-900 border mr-4"
        ></input>
        <button
          className="border-gray-900 border"
          onClick={() => searchPois(searchText)}
        >
          검색
        </button>
        {pois.map((p, index) => (
          <div key={index}>
            <span>{p.name}</span>
            <span>{p.imageUrl}</span>
            {p.imageUrl ? (
              <img src={p.imageUrl} width={200} height={150} />
            ) : (
              "No image"
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSearcher;
