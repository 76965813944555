import React, { createContext, useReducer } from "react";

const initialState = {
  userOrderList: [],
  curOrder: {
    order: {
      adults: "",
      companion: "",
      departAt: "",
      departFrom: "",
      id: "",
      kids: "",
      location: "",
      paidAt: "",
      returnAt: "",
      transportation: "",
      userEmail: "",
      userId: "",
      userName: "",
    },
    delivery: {
      customName: "",
      descriptions: "",
      editor: "",
      highlight1Id: "",
      highlight2Id: "",
      highlight3Id: "",
      id: "",
      itineraryId: "",
      tags: "",
    },
  },
};

/*
INFO: action data format

{
    type: string,
    value: Array | Order
}
*/
const reducer = (state = initialState, action) => {
  const { key, content } = action.value;

  switch (action.type) {
    case "CHANGE_ORDER_INPUT":
      const newOrderContent = { ...state.curOrder.order, [key]: content };

      return {
        ...state,
        curOrder: { delivery: state.curOrder.delivery, order: newOrderContent },
      };
    case "CHANGE_DELIVERY_INPUT":
      const newDeliveryContent = { ...state.curOrder.delivery, [key]: content };

      return {
        ...state,
        curOrder: { delivery: newDeliveryContent, order: state.curOrder.order },
      };
    case "SET_ORDER_LIST":
      return { ...state, userOrderList: action.value };
    case "SET_CUR_ORDER":
      return { ...state, curOrder: action.value };
    default:
      return state;
  }
};

const DeliveryContext = createContext([initialState, () => initialState]);

const DeliveryProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return (
    <DeliveryContext.Provider value={value}>
      {children}
    </DeliveryContext.Provider>
  );
};

export { DeliveryContext, DeliveryProvider };
