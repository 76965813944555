import Axios from "axios";

const gnashAxiosInstance = Axios.create({
  // baseURL: "http://localhost:3003",
  baseURL: "https://gnash-prod.stori.city",
});

const getPois = async () => {
  try {
    const { data } = await Axios.get("/v2/pois");

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};
const createPoi = async (poi) => {
  try {
    const { data } = await Axios.post("/v2/pois", { ...poi });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updatePoi = async (id, poi) => {
  try {
    const { data } = await Axios.put(`/v2/pois/${id}`, { ...poi });
    const { poi: updatedPoi, status } = data;

    if (status !== "success") throw new Error(`Wrong status: ${status}`);
    return updatedPoi;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const scottUpdatePoi = async (id) => {
  try {
    const { data } = await Axios.put(`/v2/pois/${id}/scott_update`);
    const { poi: updatedPoi, status } = data;

    if (status !== "success") throw new Error(`Wrong status: ${status}`);
    return updatedPoi;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const searchPoiImages = async (name) => {
  try {
    const { data } = await gnashAxiosInstance.get(
      `/v2/search_images?name=${name}`,
      {
        headers: { Authorization: Axios.defaults.headers.common.Authorization },
      }
    );

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { getPois, createPoi, updatePoi, scottUpdatePoi, searchPoiImages };
