import React from "react";

const UpdateModalField = ({ type, name, label, state, setState }) => {
  const changeHandler = (e) => {
    setState({ ...state, [name]: e.target.value });
  };

  switch (type) {
    case "static":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>
          <div>{state[name]}</div>
        </div>
      );
    case "integer":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>

          <input
            type="number"
            className="border p-2"
            step="1"
            min="0"
            value={state[name]}
            onChange={changeHandler}
          />
        </div>
      );
    case "textarea":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>

          <textarea
            className="border p-2"
            value={state[name]}
            onChange={changeHandler}
          />
        </div>
      );
    case "text":
      return (
        <div className="flex" key={name}>
          <div>{label}: </div>

          <input
            className="border p-2"
            value={state[name]}
            onChange={changeHandler}
          />
        </div>
      );
    default:
      return <div>No</div>;
  }
};

export default UpdateModalField;
