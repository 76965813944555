import React, { useContext, useEffect, useState } from "react";
import { confirmDelivery, prepareDelivery } from "../../api/Deliveries";

import Modal from "react-modal";
import UpdateModal from "./UpdateModal";
import { UserContext } from "../../contexts/user";
import { getOpenOrders } from "../../api/Orders";
import stableStringify from "json-stable-stringify";

const headers = [
  { label: "survey", width: 10 },
  { label: "order", width: 10 },
  { label: "delivery", width: 10 },
  { label: "고객명", width: 20 },
  { label: "Email", width: 20 },
  { label: "Itinerary ID", width: 10 },
  { label: "HL 1", width: 10 },
  { label: "HL 2", width: 10 },
  { label: "HL 3", width: 10 },
  { label: "location", width: 10 },
  { label: "큐레이션", width: 40 },
  { label: "태그", width: 20 },
  { label: "결제일", width: 20 },
  { label: "동작", width: 40 },
];

const OrderTable = () => {
  const [orders, setOrders] = useState([]);
  const [user] = useContext(UserContext);
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [survey, setSurvey] = useState({});
  const [targetOrderDetail, setTargetOrderDetail] = useState({});

  useEffect(() => {
    if (!!user.token) {
      getOpenOrders().then(setOrders);
    }
  }, [user]);

  const editHandler = (orderDetail) => {
    openEditModal(orderDetail);
  };

  const deliveryHandler = async (surveyId, deliveryId, userName) => {
    const isConfirmed = window.confirm(
      `${userName}님의 ${surveyId}번 주문을 발송하시겠습니까?(delivery_id: ${deliveryId})`
    );

    if (isConfirmed) {
      const updated = await confirmDelivery(deliveryId);
      if (updated) {
        getOpenOrders().then(setOrders);
      }
    }
  };

  const openSurveyModal = () => {
    setIsSurveyOpen(true);
  };
  const closeSurveyModal = () => {
    setIsSurveyOpen(false);
  };
  const openEditModal = (orderDetail) => {
    setTargetOrderDetail(orderDetail);
    setIsEditOpen(true);
  };
  const closeEditModal = () => {
    setTargetOrderDetail({});
    setIsEditOpen(false);
  };
  const surveyHandler = (surveyDetail) => {
    openSurveyModal();
    setSurvey(surveyDetail);
  };

  const previewHandler = (surveyId) => {
    window.open(`https://yodatrip.com/trip/${surveyId}`);
  };

  const prepareHandler = async (surveyId) => {
    const updated = await prepareDelivery(surveyId);
    if (updated) {
      getOpenOrders().then(setOrders);
    }
  };

  return (
    <div>
      <Modal isOpen={isSurveyOpen} onRequestClose={closeSurveyModal}>
        <div className="w-full bg-gray-800 text-white">
          <pre>
            {stableStringify(survey, {
              space: "  ",
            })}
          </pre>
        </div>
      </Modal>
      <Modal isOpen={isEditOpen} onRequestClose={closeEditModal}>
        {UpdateModal({ ...targetOrderDetail, setOrders, closeEditModal })}
      </Modal>
      <table className="table-auto w-full text-center">
        <thead>
          <tr>
            {headers.map(({ label, width }, index) => (
              <th key={index} className={`border border-gray-200 w-${width}`}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders.map((orderDetail, index) => {
            const {
              surveyId,
              orderId,
              deliveryId,
              userName,
              userEmail,
              paidAt,
              itineraryId,
              surveyDetail,
              highlights,
              tags,
              descriptions,
              location,
            } = orderDetail;

            return (
              <tr key={index} className="border-t border-b">
                <td className="border border-gray-200">{surveyId}</td>
                <td className="border border-gray-200">{orderId}</td>
                <td className="border border-gray-200">{deliveryId}</td>
                <td className="border border-gray-200">{userName}</td>
                <td className="border border-gray-200">{userEmail}</td>
                <td className="border border-gray-200">{itineraryId}</td>
                <td className="border border-gray-200">
                  {highlights ? highlights[0] : null}
                </td>
                <td className="border border-gray-200">
                  {highlights ? highlights[1] : null}
                </td>
                <td className="border border-gray-200">
                  {highlights ? highlights[2] : null}
                </td>
                <td className="border border-gray-200">{location}</td>
                <td className="border border-gray-200">{descriptions}</td>
                <td className="border border-gray-200">{tags}</td>
                <td className="border border-gray-200">{paidAt}</td>
                <td className="flex flex-wrap text-sm">
                  <button
                    className="border rounded-lg p-2 mr-2 bg-red-200"
                    onClick={() => surveyHandler(surveyDetail)}
                  >
                    설문보기
                  </button>
                  <button
                    className="border rounded-lg p-2 mr-2 bg-purple-400"
                    onClick={() => prepareHandler(surveyDetail.id)}
                  >
                    딜리버리 채우기
                  </button>
                  <button
                    className="border rounded-lg p-2 mr-2 bg-blue-400"
                    onClick={() => previewHandler(surveyDetail.id)}
                  >
                    미리보기
                  </button>
                  <button
                    className="border rounded-lg p-2 mr-2 bg-yellow-400"
                    onClick={() => editHandler(orderDetail)}
                  >
                    수정하기
                  </button>
                  <button
                    className="border rounded-lg p-2 bg-green-400"
                    onClick={() =>
                      deliveryHandler(surveyDetail.id, deliveryId, userName)
                    }
                  >
                    발송
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
