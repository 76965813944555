import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { useContext, useEffect } from "react";

import ApiDoc from "../ApiDoc";
import Axios from "axios";
import Delivery from "../Delivery";
import { DeliveryProvider } from "../../contexts/delivery";
import Fallback from "../Fallback/Fallback";
import Header from "../Header";
import ImageSearcher from "../ImageSearcher";
import Mirani from "../Mirani";
import React from "react";
import SalesOrders from "../SalesOrders";
import Signin from "../Signin";
import Signout from "../Signout";
import { ToastContainer } from "react-toastify";
import { UserContext } from "../../contexts/user";
import Weeklys from "../Weeklys";
import Welcome from "../Welcome";
import Wizard from "../Wizard";
import { WizardProvider } from "../../contexts/wizard";
import { me } from "../../api/Accounts";

function App() {
  const [, dispatchUser] = useContext(UserContext);

  useEffect(() => {
    const checkSignin = async () => {
      const token = window.localStorage.getItem("token");
      if (token) {
        Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const data = await me(token);

        if (data === false) {
          console.log("SIGN OUT");
          dispatchUser({
            type: "SIGNOUT",
          });
        } else {
          console.log("Already Signed in");
          console.log(data);
          dispatchUser({
            type: "SIGNIN",
            token: token,
            name: data.name,
            role: data.role,
          });
        }
      }
    };

    checkSignin();
  }, [dispatchUser]);

  return (
    <Router>
      <div className="flex flex-initial flex-col h-screen">
        <Header />
        <Switch>
          <Route path="/signout">
            <Signout />
          </Route>
          <Route path="/signin">
            <Signin />
          </Route>
          <Route path="/delivery">
            <DeliveryProvider>
              <Delivery />
            </DeliveryProvider>
          </Route>
          <Route path="/fallback">
            <Fallback />
          </Route>
          <Route path="/wizard">
            <WizardProvider>
              <Wizard />
            </WizardProvider>
          </Route>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route path="/mirani">
            <Mirani />
          </Route>
          <Route path="/weeklys">
            <Weeklys />
          </Route>
          <Route path="/sales_orders">
            <SalesOrders />
          </Route>
          <Route path="/imagesearcher">
            <ImageSearcher />
          </Route>
          <Route exact path="/">
            <Redirect to="/welcome" />
          </Route>
        </Switch>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
