const INPUT_TYPE = {
  area: "area",
  dropdown: "select",
  number: "number",
  fiveRating: "fiveRating",
  tenRating: "tenRating",
  decimal: "decimal",
  menu: "menu",
  hidden: "hidden",
  immutable: "immutable",
  immutableDate: "immutableDate",
  createOnly: "createOnly",
  date: "date",
  integer: "integer",
  checkbox: "checkbox",
  textarea: "textarea",
};

export default INPUT_TYPE;
