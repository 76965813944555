import React, { useContext, useState } from "react";
import { createPoi, updatePoi } from "../../api/Pois";

import EditFormInput from "./EditFormInput";
import INPUT_TYPE from "../../Types/InputType";
import { WizardContext } from "../../contexts/wizard";
import { toast } from "react-toastify";

const EditForm = ({ areas }) => {
  const [areaSmalls, setAreaSmalls] = useState([]);
  const [ctx, dispatch] = useContext(WizardContext);
  const { editTarget, isNew } = ctx;

  const areaLargeList = (
    <datalist id="areaLargeList">
      {Object.keys(areas).map((key) => (
        <option value={key} key={key} />
      ))}
    </datalist>
  );

  const areaSmallList = (
    <datalist id="areaSmallList">
      {areaSmalls.map((areaSmall) => (
        <option value={areaSmall} key={areaSmall} />
      ))}
    </datalist>
  );

  const submitHandler = async (e) => {
    e.preventDefault();
    let poi = {};
    Object.keys(editTarget).forEach((key) => {
      if (key === "menu" && typeof editTarget["menu"].value === "string") {
        poi[key] = editTarget[key].value.split("\n");
      } else {
        return (poi[key] = editTarget[key].value);
      }
    });
    const [areaLarge, areaSmall] = poi.area.split(" ");
    poi = { ...poi, areaLarge, areaSmall };

    if (isNew) {
      const resp = await createPoi(poi);
      console.log(resp);
      if (resp) {
        dispatch({
          type: "UPDATE_SINGLE_POI",
          value: { ...poi, id: resp.id },
        });
        toast.success("POI가 생성되었습니다.");
      } else {
        toast.error("POI 생성에 실패했습니다.");
      }
    } else {
      const resp = await updatePoi(poi.id, poi);
      console.log(resp);
      if (resp) {
        toast.info("POI가 업데이트되었습니다.");
        dispatch({
          type: "UPDATE_SINGLE_POI",
          value: resp,
        });
      } else {
        toast.error("POI 업데이트에 실패했습니다.");
      }
    }
  };

  return (
    <form className="p-1 pb-4" onSubmit={submitHandler}>
      {areaLargeList}
      {areaSmallList}
      {Object.entries(editTarget).map(([fieldName, obj]) => {
        return obj.type === INPUT_TYPE.hidden ? null : (
          <div className="flex gap-x-1 text-xs mb-2" key={fieldName}>
            <label className="w-24" htmlFor={fieldName}>
              {obj.label}
            </label>
            <EditFormInput
              fieldName={fieldName}
              areaSmalls={areaSmalls}
              setAreaSmalls={setAreaSmalls}
              areas={areas}
              isNew={isNew}
            />
          </div>
        );
      })}
      <div className="flex my-4">
        <input
          type="submit"
          className={
            "flex-1 text-xs py-1 px-2 mx-4 cursor-pointer" +
            (isNew ? " bg-blue-300" : " bg-green-300")
          }
          value={isNew ? "Create" : "Update"}
        />
      </div>
    </form>
  );
};

export default EditForm;
