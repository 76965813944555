import "react-toastify/dist/ReactToastify.css";

import React, { useContext, useState } from "react";

import { Redirect } from "react-router-dom";
import { UserContext } from "../../contexts/user";
import { signin } from "../../api/Accounts";

// import { ToastContainer, toast } from "react-toastify";

const Singin = ({ isSignin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, dispatchUser] = useContext(UserContext);
  const changeEmail = (event) => setEmail(event.target.value);
  const changePassword = (event) => setPassword(event.target.value);
  const submitForm = async (event) => {
    event.preventDefault();
    const data = await signin(email, password);

    if (data === false) {
      console.log("Wrong id/pw");
      alert("로그인 실패");
      dispatchUser({
        type: "SIGNOUT",
      });
    } else {
      console.log("token??", data.token);

      dispatchUser({
        type: "SIGNIN",
        token: data.token,
        name: data.user.name,
        role: data.user.role,
      });
    }
  };

  if (!!user.token) {
    return <Redirect to="/welcome" />;
  } else {
    return (
      <form onSubmit={submitForm} className="flex-1 bg-gray-200 pt-20">
        <div className="w-full grid grid-row-3 max-w-sm mx-auto bg-white py-4 px-8 shadow-md rounded-md my-auto">
          <div className="flex flex-col w-full mb-4">
            <label htmlFor="inputEmail" className="mb-2 text-sm font-bold">
              Email
            </label>
            <input
              type="email"
              className="border py-2 px-3 mb-4 shadow-md rounded"
              id="inputEmail"
              placeholder="Enter email"
              onChange={changeEmail}
              aria-describedby="emailHelp"
              name="email"
              required
            />
          </div>
          <div className="flex flex-col w-full mb-4">
            <label htmlFor="inputPassword" className="mb-2 text-sm font-bold">
              Password
            </label>
            <input
              type="password"
              className="border py-2 px-3 shadow-md rounded"
              id="inputPassword"
              placeholder="Password"
              onChange={changePassword}
              name="password"
              required
            />
          </div>
          <div className="flex flex-col">
            <button
              id="button-signin"
              type="submit"
              className="border bg-blue-500 text-white text-lg font-bold w-1/3 mb-4 py-2 rounded-lg shadow-sm"
            >
              Sign in
            </button>
            <small id="emailHelp" className="form-text text-muted">
              계정을 잊으신 경우 관리자에게 문의하세요.
            </small>
          </div>
        </div>
      </form>
    );
  }
};

export default Singin;
