import Axios from "axios";

const getOpenOrders = async () => {
  try {
    const { data } = await Axios.get("/v2/orders?status=open");

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateOrder = async (id, order) => {
  try {
    const { data } = await Axios.put(`/v2/orders/${id}`, { ...order });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateDelivery = async (order_id, delivery) => {
  try {
    const { data } = await Axios.put(`/v2/orders/${order_id}/delivery`, {
      ...delivery,
    });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateOrderDelivery = async (survey_id, detail) => {
  try {
    const { data } = await Axios.put(
      `/v2/surveys/${survey_id}/order_delivery`,
      {
        ...detail,
      }
    );

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { getOpenOrders, updateOrder, updateDelivery, updateOrderDelivery };
