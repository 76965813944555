import React, { useContext } from "react";

import { WizardContext } from "../../contexts/wizard";

const SearchBar = ({ searchHandler }) => {
  const [, dispatch] = useContext(WizardContext);

  const submitHandler = (e) => {
    e.preventDefault();
    const category = e.currentTarget.category.value;
    const value = e.currentTarget.value.value;
    if (category && value) {
      searchHandler(
        e.currentTarget.category.value,
        e.currentTarget.value.value
      );
    }
  };

  const newHandler = (e) => {
    dispatch({
      type: "OPEN_NEW",
    });
  };

  return (
    <div className="flex h-10 w-full bg-white border-r-2 border-dashed">
      <form className="flex flex-1" onSubmit={submitHandler}>
        <select
          className="border border-gray-400 p-1 m-1 mr-2 text-xs"
          name="category"
        >
          <option value="area">지역</option>
          <option value="name">이름</option>
          <option value="id">ID</option>
          <option value="zone">ZONE ID</option>
        </select>
        <input
          className="flex-1 m-1 border-b border-gray-400 outline-none"
          type="text"
          name="value"
        />
        <input
          type="submit"
          className="border bg-green-200 hover:bg-green-500 text-xs p-1 px-2 m-1"
          value="검색"
        />
      </form>
      <button
        className="bg-green-800 text-xs text-white p-1 px-2 m-1"
        onClick={newHandler}
      >
        추가
      </button>
    </div>
  );
};

export default SearchBar;
