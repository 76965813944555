// import OrderTable from "./OrderTable";
import React from "react";
import WeeklyTable from "./WeeklyTable";

const Weeklys = () => {
  return (
    <div>
      <WeeklyTable />
    </div>
  );
};

export default Weeklys;
