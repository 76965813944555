import Axios from "axios";

const signin = async (email, password) => {
  try {
    const { data } = await Axios.post("/v2/accounts/signin", {
      email,
      password,
    });

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const me = async () => {
  try {
    const { data } = await Axios.get("v2/accounts/me");

    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export { signin, me };
