import "./styles/index.css";
import "./styles/tailwind.output.css";

import * as serviceWorker from "./serviceWorker";

import App from "./components/App/App";
import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { UserProvider } from "./contexts/user";

Axios.defaults.baseURL = "https://api.stori.city";
// Axios.defaults.baseURL = "http://localhost:4000";

ReactDOM.render(
  <React.Fragment>
    <UserProvider>
      <App />
    </UserProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
