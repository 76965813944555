import React, { useContext } from "react";

import EditForm from "./EditForm";
import SelectedPoiAttrType from "../../Types/SelectedPoiAttrType";
import { WizardContext } from "../../contexts/wizard";
import { getTransports } from "../../api/Transports";
import { scottUpdatePoi } from "../../api/Pois";
import { toast } from "react-toastify";

const Edit = ({ areas, useSelectedPois }) => {
  const [ctx, dispatch] = useContext(WizardContext);
  const { pois, editTarget, isNew } = ctx;
  const [selectedPois, setSelectedPois] = useSelectedPois;
  const closeEdit = () => {
    dispatch({ type: "CLOSE_EDIT" });
  };

  const scottHandler = async (e) => {
    e.preventDefault();
    const {
      id: { value },
    } = editTarget;

    const resp = await scottUpdatePoi(value);
    console.log(resp);

    if (resp) {
      toast.success("SCOTT 업데이트 성공!");
      dispatch({
        type: "UPDATE_SINGLE_POI",
        value: resp,
      });
    } else {
      toast.error("SCOTT 업데이트 실패");
    }
  };

  const addHandler = async (e) => {
    e.preventDefault();
    const {
      id: { value: poiId },
    } = editTarget;
    const newPoi = pois[poiId];
    newPoi.attr = SelectedPoiAttrType.normal;

    const selectedPoiArray = Array.from(selectedPois);
    selectedPoiArray.push(newPoi);
    const poiIds = [];
    selectedPoiArray.forEach((poi) => {
      if (poi.attr !== SelectedPoiAttrType.sub) {
        poiIds.push(poi.id);
      }
    });
    const transports = await getTransports(poiIds);

    let lastMainPoiId = poiIds[0];
    const _selectedPois = selectedPoiArray.map((poi) => {
      const transportIndex = transports.findIndex((trans) =>
        pois[lastMainPoiId]
          ? trans.to === poi.id && trans.from === pois[lastMainPoiId].id
          : trans.to === poi.id
      );
      if (poi.attr !== SelectedPoiAttrType.sub) {
        lastMainPoiId = poi.id;
      }
      return {
        ...poi,
        transportMin:
          transportIndex > -1 ? transports[transportIndex].min : undefined,
      };
    });
    setSelectedPois(_selectedPois);
  };

  return (
    <div
      className={`w-64 ${
        isNew ? "bg-green-100" : "bg-blue-200"
      } h-full overflow-y-auto`}
    >
      <div className="flex justify-end text-xs p-1">
        {!isNew ? (
          <button
            className="border p-1 px-2 m-1 bg-blue-300 hover:bg-blue-500"
            onClick={addHandler}
          >
            Add
          </button>
        ) : null}
        {!isNew ? (
          <button
            className="border p-1 m-1 bg-yellow-200 hover:bg-yellow-500"
            onClick={scottHandler}
          >
            Scott
          </button>
        ) : null}
        <button
          className="border p-1 m-1 bg-green-200 hover:bg-green-500"
          onClick={closeEdit}
        >
          Close
        </button>
      </div>
      <p className="text-center font-bold text-2xl mb-4">
        {editTarget ? `${editTarget.name.value}` : "새 POI 추가하기"}
      </p>
      <div>
        {editTarget && editTarget.mainImageUrl.value ? (
          <img src={editTarget.mainImageUrl.value} />
        ) : null}
      </div>
      <EditForm areas={areas} />
    </div>
  );
};

export default Edit;
