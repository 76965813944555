import { Link } from "react-router-dom";
import React from "react";
import { UserContext } from "../../contexts/user";
import { useContext } from "react";

const Header = () => {
  const [user] = useContext(UserContext);

  return (
    <div className="bg-green-400 h-10 flex px-10">
      <div className="ml-10 flex-1 my-auto">
        <Link to="/">
          <span className="text-xl text-white font-extrabold">
            트래비스 위자드
          </span>
        </Link>
      </div>
      <div className="flex gap-x-10 w-auto pr-10">
        <Link to="/sales_orders" className="my-auto">
          <div className="text-base text-gray-800 text-center">
            <span>주문 관리</span>
          </div>
        </Link>
        <Link to="/imagesearcher" className="my-auto">
          <div className="text-base text-gray-800 text-center">
            <span>이미지검색</span>
          </div>
        </Link>
        <Link to="/weeklys" className="my-auto">
          <div className="text-base text-gray-800 text-center">
            <span>주간 여다</span>
          </div>
        </Link>
        <Link to="/wizard" className="my-auto">
          <div className="text-base text-gray-800 text-center">
            <span>위자드</span>
          </div>
        </Link>
        <Link to="/mirani" className="my-auto">
          <div className="text-base text-gray-800 text-center">
            <span>미란이</span>
          </div>
        </Link>
        <Link to="/delivery" className="my-auto">
          <div className="text-base text-gray-800 text-center">
            <span>딜리버리</span>
          </div>
        </Link>
        {!!user.token ? (
          <Link to="/signout" className="my-auto">
            <div className="text-base text-gray-800 text-center">
              <span>로그아웃</span>
            </div>
          </Link>
        ) : (
          <Link to="/signin" className="my-auto">
            <div className="text-base text-gray-800 text-center">
              <span>로그인</span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
