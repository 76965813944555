import React, { useContext, useEffect, useState } from "react";
import {
  createWeeklyArticle,
  getWeeklyArticles,
  sendPushNotification,
} from "../../api/WeeklyArticles";

import Modal from "react-modal";
import UpdateModal from "./UpdateModal";
import { UserContext } from "../../contexts/user";

const WeeklyTable = () => {
  const [user] = useContext(UserContext);
  const [articles, setArticles] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [targetArticleDetail, setTargetArticleDetail] = useState({});
  const [mobile, setMobile] = useState("");
  const [seg1Title, setSeg1Title] = useState("");
  const [seg1Body, setSeg1Body] = useState("");
  const [seg2Title, setSeg2Title] = useState("");
  const [seg2Body, setSeg2Body] = useState("");
  const [seg3Title, setSeg3Title] = useState("");
  const [seg3Body, setSeg3Body] = useState("");
  const [seg4Title, setSeg4Title] = useState("");
  const [seg4Body, setSeg4Body] = useState("");
  const [seg5Title, setSeg5Title] = useState("");
  const [seg5Body, setSeg5Body] = useState("");
  const [seg6Title, setSeg6Title] = useState("");
  const [seg6Body, setSeg6Body] = useState("");
  const [pushResult, setPushResult] = useState("");
  const [pushEnabled, setPushEnabled] = useState(true);

  const handleSetTargetArticleDetail = (articleDetail) => {
    const {
      id,
      is_active,
      type,
      seg_id,

      content: {
        area,
        title,
        value,
        length,
        poi_id,
        location,
        sub_title,
        show_name,
        survey_id,
        area_small,
        description,
        description1,
        description2,
        itinerary_id,
        main_image_url,
        period,
        insta,
      },
    } = articleDetail;

    // [
    //   {
    //     link: insta1_link,
    //     author_name: insta1_author,
    //     thumbnail_url: insta1_thumbnail,
    //   },
    //   {
    //     link: insta2_link,
    //     author_name: insta2_author,
    //     thumbnail_url: insta2_thumbnail,
    //   },
    //   {
    //     link: insta3_link,
    //     author_name: insta3_author,
    //     thumbnail_url: insta3_thumbnail,
    //   },
    //   {
    //     link: insta4_link,
    //     author_name: insta4_author,
    //     thumbnail_url: insta4_thumbnail,
    //   },
    // ]

    const refined = {
      id,
      is_active,
      type,
      seg_id,
      area,
      title,
      value,
      length,
      poi_id,
      location,
      sub_title,
      show_name,
      survey_id,
      area_small,
      description,
      description1,
      description2,
      itinerary_id,
      main_image_url,
      period,
      ...(insta[0]
        ? {
            insta1_link: insta[0].link,
            insta1_author: insta[0].author_name,
            insta1_thumbnail: insta[0].thumbnail_url,
          }
        : {}),
      ...(insta[1]
        ? {
            insta2_link: insta[1].link,
            insta2_author: insta[1].author_name,
            insta2_thumbnail: insta[1].thumbnail_url,
          }
        : {}),
      ...(insta[2]
        ? {
            insta3_link: insta[2].link,
            insta3_author: insta[2].author_name,
            insta3_thumbnail: insta[2].thumbnail_url,
          }
        : {}),
      ...(insta[3]
        ? {
            insta4_link: insta[3].link,
            insta4_author: insta[3].author_name,
            insta4_thumbnail: insta[3].thumbnail_url,
          }
        : {}),
    };

    setTargetArticleDetail(refined);
  };

  const createArticle = async () => {
    await createWeeklyArticle();
    getWeeklyArticles().then(setArticles);
  };

  useEffect(() => {
    getWeeklyArticles().then(setArticles);
  }, [user]);

  const editHandler = (articleDetail) => {
    openEditModal(articleDetail);
  };

  const openEditModal = (articleDetail) => {
    handleSetTargetArticleDetail(articleDetail);
    setIsEditOpen(true);
  };
  const closeEditModal = () => {
    setTargetArticleDetail({});
    setIsEditOpen(false);
  };

  const sendPush = async () => {
    if (window.confirm("푸시 알림을 발송하시겠습니까?")) {
      setPushResult("");
      setPushEnabled(false);
      const _result = await sendPushNotification({
        mobile,
        seg1Title,
        seg1Body,
        seg2Title,
        seg2Body,
        seg3Title,
        seg3Body,
        seg4Title,
        seg4Body,
        seg5Title,
        seg5Body,
        seg6Title,
        seg6Body,
      });
      if (_result) {
        setPushResult(JSON.stringify(_result));
      }
      setPushEnabled(true);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <Modal
        isOpen={isEditOpen}
        onRequestClose={closeEditModal}
        ariaHideApp={false}
      >
        {/* <div className="w-full bg-gray-800 text-white">hihi</div> */}
        {UpdateModal({ ...targetArticleDetail, setArticles, closeEditModal })}
      </Modal>
      <div className="mb-20">
        <input
          className="w-80 border"
          type="string"
          placeholder="테스트 휴대폰 번호"
          value={mobile}
          onChange={(e) => setMobile(e.currentTarget.value)}
        />
        <div className="flex">
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 1 타이틀"
            value={seg1Title}
            onChange={(e) => setSeg1Title(e.currentTarget.value)}
          />
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 1 본문"
            value={seg1Body}
            onChange={(e) => setSeg1Body(e.currentTarget.value)}
          />
        </div>
        <div className="flex">
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 2 타이틀"
            value={seg2Title}
            onChange={(e) => setSeg2Title(e.currentTarget.value)}
          />
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 2 본문"
            value={seg2Body}
            onChange={(e) => setSeg2Body(e.currentTarget.value)}
          />
        </div>
        <div className="flex">
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 3 타이틀"
            value={seg3Title}
            onChange={(e) => setSeg3Title(e.currentTarget.value)}
          />
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 3 본문"
            value={seg3Body}
            onChange={(e) => setSeg3Body(e.currentTarget.value)}
          />
        </div>
        <div className="flex">
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 4 타이틀"
            value={seg4Title}
            onChange={(e) => setSeg4Title(e.currentTarget.value)}
          />
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 4 본문"
            value={seg4Body}
            onChange={(e) => setSeg4Body(e.currentTarget.value)}
          />
        </div>
        <div className="flex">
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 5 타이틀"
            value={seg5Title}
            onChange={(e) => setSeg5Title(e.currentTarget.value)}
          />
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 5 본문"
            value={seg5Body}
            onChange={(e) => setSeg5Body(e.currentTarget.value)}
          />
        </div>
        <div className="flex">
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 6 타이틀"
            value={seg6Title}
            onChange={(e) => setSeg6Title(e.currentTarget.value)}
          />
          <input
            className="flex-1 border"
            type="string"
            placeholder="세그먼트 6 본문"
            value={seg6Body}
            onChange={(e) => setSeg6Body(e.currentTarget.value)}
          />
        </div>
        <button
          disabled={!pushEnabled}
          className={`border p-2 ${pushEnabled ? "bg-green-200" : ""}`}
          onClick={sendPush}
        >
          푸시 알림 발송하기
        </button>
        <div>{pushResult}</div>
      </div>
      <div className="flex h-10 bg-green-100">
        <button
          className="bg-blue-300 border-black border-2"
          onClick={createArticle}
        >
          새 Article 생성
        </button>
      </div>
      <table className="flex-1">
        <thead>
          <tr>
            <th>id</th>
            <th>is_active</th>
            <th>type</th>
            <th>seg id</th>
            <th>content</th>
            <th>inserted_at</th>
            <th>updated_at</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          {articles &&
            articles
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((a, index) => {
                return (
                  <tr
                    key={index}
                    className={`border-b border-t ${
                      index % 2 === 0 ? "bg-green-100" : ""
                    }`}
                  >
                    <td>{a.id}</td>
                    <td>{a.is_active.toString()}</td>
                    <td>{a.type}</td>
                    <td>{a.seg_id}</td>
                    <td>{a.content.description}</td>
                    <td>{a.inserted_at}</td>
                    <td>{a.updated_at}</td>
                    <td className="flex gap-x-4">
                      <button
                        className="bg-blue-200 border-2 border-black"
                        onClick={() => editHandler(a)}
                      >
                        Content 수정
                      </button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyTable;
