/*
{
    pois: {
        1: {id: 10, name: "여기저기", ... },
        2: {id: 11, name: "저기거기", ... },
        3: {id: 12, name: "저기거기", ... },
        4: {id: 13, name: "저기거기", ... },
        ...
    },
    editTarget: {
        id: {
            value: 30, 
            ...
        }, 
        name: {
            value: "새POI", 
            ...
        }, 
        ...
    },
    showEdit: true,
    isNew: true,
    areas: {
        "서울": ["서울"],
        "경기": ["성남", "화성"],
        ...
    },
    mode: "POI_EDITOR" // "ZONE_EDITOR",
    zones: [],
    ediitingZonePath: [],
    ediitingZoneId: undefined,
    showExistingZones: false,
    parentItnId: number,
}
*/
const WIZARD_CONTEXT = {
  pois: {},
  editTarget: null,
  showEdit: false,
  isNew: true,
  areas: {},
  mode: "POI_EDITOR",
  zones: [],
  editingZonePath: [],
  editingZoneId: undefined,
  showExistingZones: true,
  parentItnId: null,
};

export default WIZARD_CONTEXT;
