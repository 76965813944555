import React, { useCallback, useContext } from "react";
import { getDests, postItn } from "../../api/Itns";

import Card from "./Card";
import { Droppable } from "react-beautiful-dnd";
import SelectedPoiAttrType from "../../Types/SelectedPoiAttrType";
import { WizardContext } from "../../contexts/wizard";
import { getTransports } from "../../api/Transports";
import { toast } from "react-toastify";

const Course = ({ id, useSelectedPois }) => {
  const [{ pois, parentItnId }, dispatch] = useContext(WizardContext);
  const [selectedPois, setSelectedPois] = useSelectedPois;
  const selectPoi = (poi) => {
    dispatch({ type: "SELECT", value: poi });
  };

  const setParentId = (id) => {
    dispatch({ type: "SET_PARENT_ID", value: id });
  };

  const isPoisReady = () => {
    return Object.keys(pois).length !== 0;
  };

  const loadHandler = async (e) => {
    e.preventDefault();
    const itnId = e.currentTarget.itnId.value;

    if (isPoisReady() && itnId.length !== 0 && selectedPois.length === 0) {
      const dests = await getDests(itnId);
      const poiIds = dests.map((dest) => dest[2]);
      const attrs = dests.map((dest, index) => {
        if (index === 0 || dests[index - 1][0] !== dests[index][0]) {
          return SelectedPoiAttrType.newday;
        } else if (dests[index - 1][1] === dests[index][1]) {
          return SelectedPoiAttrType.sub;
        } else {
          return SelectedPoiAttrType.normal;
        }
      });
      const _selectedPois = poiIds
        .map((id) => pois[id])
        .map((poi, index) => {
          return {
            ...poi,
            attr: attrs[index],
          };
        });

      const transportPoiIds = [];
      _selectedPois.forEach((poi) => {
        if (poi.attr !== SelectedPoiAttrType.sub) {
          transportPoiIds.push(poi.id);
        }
      });
      const transports = await getTransports(transportPoiIds);

      let lastMainPoiId = transportPoiIds[0];
      setSelectedPois(
        _selectedPois.map((poi) => {
          const transportIndex = transports.findIndex((trans) =>
            pois[lastMainPoiId]
              ? trans.to === poi.id && trans.from === pois[lastMainPoiId].id
              : trans.to === poi.id
          );
          if (poi.attr !== SelectedPoiAttrType.sub) {
            lastMainPoiId = poi.id;
          }
          return {
            ...poi,
            transportMin:
              transportIndex > -1 ? transports[transportIndex].min : undefined,
          };
        })
      );
      setParentId(itnId);
    } else {
      if (!isPoisReady()) {
        toast.warning("위자드를 준비중입니다.");
      } else if (itnId.length === 0) {
        toast.warning("ID를 입력해주세요.");
      } else {
        toast.warning("일정표를 불러오려면 작업중인 항목을 비워주세요.");
      }
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    let day = 0;
    let order = 1;
    const itinBody = selectedPois.map((poi, index) => {
      if (poi.attr === SelectedPoiAttrType.newday) {
        day += 1;
        order = 1;
      } else if (poi.attr === SelectedPoiAttrType.normal) {
        order += 1;
      }
      return [day, order, poi.id];
    });
    const resp = await postItn(itinBody, parentItnId);

    if (resp.status === "created") {
      toast.success(`Itinerary 저장 성공: ${resp.id}번`);
    } else {
      toast.error("문제가 발생했습니다. 관리자에게 문의해주세요.");
    }
  };

  const clearHandler = () => {
    setSelectedPois([]);
  };

  const toggleCardAttr = async (index, type) => {
    const selectedPoiArray = Array.from(selectedPois);
    selectedPoiArray[index].attr = type;
    const poiIds = [];
    selectedPoiArray.forEach((poi) => {
      if (poi.attr !== SelectedPoiAttrType.sub) {
        poiIds.push(poi.id);
      }
    });
    const transports = await getTransports(poiIds);

    let lastMainPoiId = poiIds[0];
    const _selectedPois = selectedPoiArray.map((poi) => {
      const transportIndex = transports.findIndex((trans) =>
        pois[lastMainPoiId]
          ? trans.to === poi.id && trans.from === pois[lastMainPoiId].id
          : trans.to === poi.id
      );
      if (poi.attr !== SelectedPoiAttrType.sub) {
        lastMainPoiId = poi.id;
      }
      return {
        ...poi,
        transportMin:
          transportIndex > -1 ? transports[transportIndex].min : undefined,
      };
    });
    setSelectedPois(_selectedPois);
  };

  const getMaxPoiId = useCallback(() => {
    let maxPoiId = Object.values(pois).reduce((id, poi) => {
      if (id > poi.id) {
        return id;
      }
      return poi.id;
    }, 0);
    return maxPoiId + 1;
  }, [pois]);

  return (
    <div className="w-64 flex flex-col h-full">
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            className="w-full flex-1 border-t-2 border-b-2 border-r-2 border-dashed h-4/5 overflow-y-auto"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {selectedPois.map((poi, index) =>
              Card({
                poi: poi,
                id: getMaxPoiId() + index,
                selectPoi,
                index,
                toggleCardAttr,
                isCourseCard: true,
                isNewday: poi.attr === SelectedPoiAttrType.newday,
                isSub: poi.attr === SelectedPoiAttrType.sub,
                transportMin: poi.transportMin,
              })
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className="flex justify-center items-center p-4 text-xs w-full">
        <button
          className="text-gray-800 border-2 border-dashed p-2 bg-red-400 w-12 mr-2"
          onClick={clearHandler}
        >
          Clear
        </button>
        <form onSubmit={loadHandler}>
          <div className="flex items-center">
            <input
              className="border-2 border-dashed p-2 mr-2 w-20"
              type="number"
              placeholder="itn_id"
              step="1"
              min="0"
              name="itnId"
            ></input>
            <input
              type="submit"
              className={`text-gray-800 border-2 border-dashed p-2 ${
                isPoisReady() ? "bg-green-200" : "bg-gray-200"
              } w-12 mr-2`}
              value="Load"
            />
          </div>
        </form>
        <button
          className="text-gray-800 border-2 border-dashed p-2 bg-blue-400 w-12"
          onClick={saveHandler}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Course;
