import React, { useContext } from "react";

import { Redirect } from "react-router-dom";
import { UserContext } from "../../contexts/user";
import { useEffect } from "react";

const Singout = () => {
  const [, dispatchUser] = useContext(UserContext);

  useEffect(() => {
    dispatchUser({ type: "SIGNOUT" });
  }, [dispatchUser]);

  return <Redirect to="/welcome" />;
};

export default Singout;
