import React, { useContext } from "react";

import Card from "./Card";
import { Droppable } from "react-beautiful-dnd";
import { WizardContext } from "../../contexts/wizard";
import POI_TYPE from "../../Types/PoiType";

const List = ({ id, activePois, filter }) => {
  const [, dispatch] = useContext(WizardContext);
  const selectPoi = (poi) => {
    dispatch({
      type: "SELECT",
      value: poi,
    });
  };

  return (
    <div className="flex flex-col h-full">
      <Droppable droppableId={id} isDropDisabled={true}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            className="w-64 flex-1 border-r-2 border-t-2 border-dashed h-full overflow-y-auto"
            ref={provided.innerRef}
          >
            {activePois
              .filter((poi) => poi !== undefined)
              .filter((poi) =>
                filter.grave
                  ? filter[POI_TYPE[poi.poiTypeId]] || poi.grade === "무덤"
                  : filter[POI_TYPE[poi.poiTypeId]] && poi.grade !== "무덤"
              )
              .map((poi, index) =>
                Card({ poi: poi, id: poi.id, selectPoi, index })
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default List;
